import ICreditAnalysisRequestForm from "../../interfaces/credit-analysis-request-form";

const creditAnalysisRequestFormikInitialValues : ICreditAnalysisRequestForm = { 
  business_profile: {
    business_name: '',
    rrss: {
      website: '',
      linkedin: '',
      twitter: '',
      instagram: '',
    },
    rif_not_exists: false,
    international_constitution: undefined,
    international_business_country: 'Venezuela',
    national: true,
    country: 'Venezuela',
    documentation: {
      tax_id: undefined,
      international_tax_id: undefined,
    },
  },
  business_activity: {
    first_activity: undefined,
    second_activity: undefined,
    raw_description: '',
    history: '',
    success_factor: '',
  }
}

export { creditAnalysisRequestFormikInitialValues };