import { ErrorMessage, Field, FieldProps } from "formik";
import { Fragment } from "react";
import Formatter from "../../services/formatter";
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

// TODO: Move this to a separate file
type FormikFieldProps = FieldProps & { 
  label: string; 
  name: string; 
  type: string; 
  placeholder: string; 
}

export function FormikField({ field, form: { touched, errors }, label, ...props }: FormikFieldProps): JSX.Element {
  
  return (
    <div>
      <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <div className={
          Formatter.classNames(
            'mt-1',
            touched[field.name] && errors[field.name] ?
              'relative rounded-md shadow-sm' : ''
          )
        }>
          <input
            id={field.name}
            className={
              Formatter.classNames(
                'block w-full rounded-md sm:text-sm',
                touched[field.name] && errors[field.name] ? 
                  'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                  'border-gray-300 shadow-sm focus:border-highlight-primary focus:ring-highlight-primary'
              )
            }
            aria-describedby={
              touched[field.name] && errors[field.name] ?
                `${field.name}-error` : undefined
            }
            aria-invalid={touched[field.name] && Boolean(errors[field.name])}
            {...field}
            {...props}
          />
          {touched[field.name] && errors[field.name] ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          ) : null}
        </div>
        <ErrorMessage name={field.name}>
          {message => (
            <p className="mt-2 text-sm text-red-600" id={`${field.name}-error`}>
              {message}
            </p>
          )}
        </ErrorMessage>
      </div>
    </div>
  )
}