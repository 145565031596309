import IBusinessModel from "../interfaces/business-model";

const businessModels: IBusinessModel[] = [{
  name: 'retailer',
  display_name: 'Retailer',
  description: 'A retailer is the last link in the supply chain. These businesses purchase goods from manufacturers or distributors and then sell them to customers for a price that will both cover expenses and turn a profit.\nRetailers may specialize in a particular niche or carry a range of products.\nExamples: Many of the businesses you patronize day to day are probably retailers, from grocery stores to pharmacies to florists.',
}, {
  name: 'manufacturer',
  display_name: 'Manufacturer',
  description: 'A manufacturer converts raw materials into products. Then, they sell those products to distributors, retailers or directly to consumers.\nExample: Manufacturing businesses build everything from furniture to pharmaceuticals. They can be companies of any size and in almost any industry.',
}, {
  name: 'fee-for-service',
  display_name: 'Fee-for-service',
  description: 'A fee-for-service is just what it sounds like: A business charges a set fee for a specific service. A business set up on this model can increase its earnings by doing work for additional clients or by raising its rates.\nDepending on what type of work the business does, it might charge an hourly rate, monthly retainer or commission. It may also create a fee schedule with a set rate for different types of services.\nExample: Hairstylists, accountants and real estate agents all charge fees for their specialized services. They may work independently or be affiliated with a salon, office or brokerage that provides resources in exchange for a percentage of their earnings.',
}, {
  name: 'subscription',
  display_name: 'Subscription',
  description: 'A subscription business model can be applied to both traditional brick-and-mortar stores and e-commerce businesses alike. Essentially, the customer makes a recurring payment for ongoing access to a service or product. A company may directly ship its product in the mail, or you may pay a fee to use its services.\nExample: Many local farms offer farm shares or community-supported agriculture subscriptions, where clients get access to fresh produce on an ongoing basis while crops are in season.',
}, {
  name: 'bundling',
  display_name: 'Bundling',
  description: 'The bundling business model involves companies selling two or more products together as a single unit, often for a lower price than they would charge selling the products separately.\nThis type of business model allows companies to generate a greater volume of sales and perhaps market products or services that are more difficult to sell. However, profit margins often shrink since businesses sell the products for less.\nExample: Many class-based fitness centers and gyms use a type of bundling model, where clients pay fees for a certain number of classes per month. The more classes a client buys, the cheaper each individual class becomes, even though their total spend increases.',
}, {
  name: 'product-as-service',
  display_name: 'Product-as-a-service',
  description: 'Product-as-a-service businesses charge customers to use physical products. They may charge a subscription fee, a per-use or per-mile fee, or a combination of both.\nExample: Bike rental companies offer products as a service. Customers might pay an annual membership fee plus a per-mile fee each time they ride, or they might have the option to rent a bike for the day.',
}, {
  name: 'leasing',
  display_name: 'Leasing',
  description: 'Under a leasing business model, a company buys a product from a seller. That company then allows another company to use the product they purchased for a recurring fee. Leasing agreements are usually most efficient with big-ticket items like manufacturing and medical equipment, but some companies lease smaller items too.\nLeasing is similar to the product-as-a-service business model, but leases usually have longer terms — days or weeks compared to minutes or hours. Leasing companies are unlikely to charge a subscription or membership fee for access to their products.\nExample: A business that rents machinery like backhoes, augers and dozers to individuals for their home construction projects is using a leasing business model.',
}, {
  name: 'franchise',
  display_name: 'Franchise',
  description: 'A franchise is an established business blueprint that a franchisee purchases and reproduces. The franchiser, or original owner, works with the franchisee to help them with financing, marketing and other business operations to ensure the business functions as it should. In return, the franchisee pays the franchiser a percentage of the profits.\nExample: Domino’s, Anytime Fitness and Ace Hardware are all examples of the franchise model.',
}, {
  name: 'distribution',
  display_name: 'Distribution',
  description: 'A company operating as a distributor is responsible for taking manufactured goods to the market. To make a profit, distributors buy the product in bulk and sell it to retailers at a higher price.\nExample: A chain of beauty salons that buys supplies in bulk and sells some of them to other salons is using a distribution business model, though they may have other revenue streams too.',
}, {
  name: 'freemium',
  display_name: 'Freemium',
  description: 'n a freemium model, customers can use parts of a product or service for free but must pay for access to more advanced features. This model is common in the software-as-a-service space — Spotify, for instance, has a free ad-supported tier, but subscribers get to listen ad-free.\nExample: Some news and internet publishing companies use a freemium model, where some or all content is free but premium content or special features are paywalled.',
}, {
  name: 'advertising',
  display_name: 'Advertising or affiliate marketing',
  description: 'The advertising and affiliate marketing business models leverage a business’s audience as an asset.\nWith advertising, a business sell its audience’s attention. Advertisers pay for space — whether it’s in the pages of a magazine or on the side of a vehicle — with rates usually determined by the size of the business\'s audience.\nWith affiliate marketing, a business earns a commission when a member of its audience buys a product or service it recommends. If you’ve ever heard a podcaster encourage you to use a specific offer code when you buy a product they’re promoting, affiliate marketing is probably part of the podcaster\'s business model.\nExample: A fashion blogger who sells ads on their podcast or website is using an advertising model. If they post outfit-of-the-day photos with links that viewers can click to “get the look,” they might also earn an affiliate marketing commission on those purchases.',
}, {
  name: 'razor-blades',
  display_name: 'Razor blades',
  description: 'To understand the razor blades model, you can simply look to your local drugstore. You’ll notice that replacement razor blades may cost more than razors themselves.\nCompanies offer a cheaper razor with the understanding that you’ll continue to purchase more expensive accessories — in this case, razor blades — in the future.\nIn addition to the traditional razor blades model, you\'ll also see companies use the reverse razor blades model, in which they offer customers a high-margin product and then promote the sales of lower-margin products that accompany that initial product.\nExamples: This business model is most common among companies that sell physical products. Printers that require a specific type of ink or water pitchers that require a specific type of filter are examples of the razor blades model.',
}];

export function getBusinessModels(): IBusinessModel[] {
  return businessModels;
}