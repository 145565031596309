import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";
import FormStep from "../../interfaces/form-step";

export default function FormikSteps({ steps }: {steps: FormStep[]}) {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center" aria-label="Progress">
            <ol className="space-y-6">
              {steps.map((step) => (
                <li key={step.name}>
                  {step.status === 'complete' ? (
                    <Link to={step.href} className="group">
                      <span className="flex items-start">
                        <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                          <CheckCircleIcon
                            className="h-full w-full text-primary group-hover:text-highlight-primary"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </Link>
                  ) : step.status === 'current' ? (
                    <Link to={step.href} className="flex items-start" aria-current="step">
                      <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-primary" />
                        <span className="relative block h-2 w-2 rounded-full bg-primary" />
                      </span>
                      <span className="ml-3 text-sm font-medium text-primary">{step.name}</span>
                    </Link>
                  ) : step.status === 'incomplete' ? (
                    <Link to={step.href} className="group">
                      <span className="flex items-start">
                        <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                          <MinusCircleIcon
                            className="h-full w-full text-yellow-400 group-hover:text-yellow-600"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </Link>
                  ) : (
                    <Link to={step.href} className="group">
                      <div className="flex items-start">
                        <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center" aria-hidden="true">
                          <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                        </div>
                        <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</p>
                      </div>
                    </Link>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
  )
}