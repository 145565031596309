import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import CreditAnalysisRequestForm from './components/credit-analysis-request-form/credit-analysis-request-form';
import { PrivateRoute } from './components/private-route/private-route';
import { AppContext } from './contexts/app.context';
import { User } from './interfaces/user';
import { Dashboard } from './sections/dashboard/dashboard';
import LoanRequestForm from './sections/loan-request-form/loan-request-form';
import Login from './sections/login/login';
import { AuthGoogle } from './sections/auth-google/auth-google';
import Signup from './sections/signup/signup';
import { getProfile } from './services/user';
import PasswordRecovery from './sections/password-recovery/password-recovery';

function App() {
  const [user, setUser] = React.useState<User | undefined>();
  
  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const profile = await getProfile();
        if ('id' in profile) {
          profile.token = token;
          setUser(profile);
        }
      }
    }
    if (user) {
      return;
    }
    fetchProfile();

  }, [user]);
  return (
    <AppContext.Provider value={{ user, setUser }}>
      <BrowserRouter>
        <Routes>
          <Route element={<Login />} path='/login' />
          <Route element={<Signup />} path='/signup' />
          <Route element={<PasswordRecovery />} path='/password-recovery' />
          <Route element={<AuthGoogle />} path='/auth/google' />
          <Route element={<PrivateRoute><Dashboard /></PrivateRoute>} path='/' />
          <Route element={<PrivateRoute><LoanRequestForm /></PrivateRoute>} path='onboarding-form/' />
          <Route element={<PrivateRoute><CreditAnalysisRequestForm /></PrivateRoute>} path='analysis-request/*' />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
