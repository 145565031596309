import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/header';

export function Dashboard(): JSX.Element {
  return (
    <Fragment>
      <Header />
      <div className='flex flex-col max-w-7xl mx-auto pt-5'>
        <h1 className='text-4xl mb-5'>Dashboard</h1>
        <p className='text-base'>En construcción</p>
        <div className='flex justify-center'>
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-highlight-primary focus:outline-none focus:ring-2 focus:ring-highlight-primary focus:ring-offset-2"
          >
            <Link to='/analysis-request'>Vamos al form</Link>
          </button>
        </div>
      </div>
    </Fragment>
  )
}
