import axios, { AxiosHeaders } from 'axios';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_XANO_API_URL_V1}`,
});

// Request interceptor that adds authentication token to every request 
apiClient.interceptors.request.use((config) => {
  
  const token = localStorage.getItem('token');

  if (token && config.headers) {
    (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`);
  }

  return config;
}, (error) => {
  Promise.reject(error);
});

apiClient.interceptors.response.use((response) => {
  return response;
}, (error) => {

  // TODO: Add logic to redirect to login screen on 401 responses

  return Promise.reject(error);
});

export { apiClient };