import { AxiosError, AxiosResponse } from 'axios';
import { GoogleLoginForm } from '../interfaces/google-login-form';
import { LoginForm } from '../interfaces/login-form';
import { SignupForm } from '../interfaces/signup-form';
import { User } from '../interfaces/user';
import { apiClient } from './base-api-client';

/**
 * Attempt to login with the provided credentials
 * 
 * @param data The login credentials
 * @returns Object containing the authentication token and user details
 */
const getProfile = async (): Promise<User | AxiosError> => {
  return apiClient.get<string, AxiosResponse<User>>('/auth/me')
    .then((response: AxiosResponse<User>) => response.data)
    .catch((error: any) => {
      return error;
    });
};

export { getProfile };