import { Fragment } from "react";
import { Navigate, Route, RouteProps, useNavigate } from "react-router-dom";
import { tokenService } from "../../services/token";

export function PrivateRoute({children, ...rest}: RouteProps) {
  const navigate = useNavigate();
  const token = tokenService.getLocalAccessToken();
  if (!token) {
    return <Navigate to="/login" replace />
  }
  return <Fragment>{children}</Fragment>;
}
