
type ButtonProps = {
  text: string;
  type: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

export function Button({ text, ...props }: ButtonProps): JSX.Element {
  return (
    <button
      className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-highlight-primary focus:outline-none focus:ring-2 focus:ring-highlight-primary focus:ring-offset-2"
      {...props}
    >
      {text}
    </button>
  )
}