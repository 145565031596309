type GoogleAuthButtonProps = {
  redirectUrl: string;
  className?: string;
}

export function GoogleAuthButton({ redirectUrl, className = '', ...rest }: GoogleAuthButtonProps): JSX.Element {
  
  const handleGoogleAuth = () => {
    window.location.href = 'https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=online&client_id=2686278489-eon5r1r6vbs4c54furrbmro5dplhr2ge.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fgoogle&state=&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&approval_prompt=auto';
  }

  return (
    <div className={`grid space-y-4 ${className}`}>
      <button className="group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-highlight-primary focus:bg-blue-50 active:bg-blue-100"
        onClick={handleGoogleAuth}
        type="button"
      >
        <div className="relative flex items-center space-x-4 justify-center">
          <img src="google.svg" className="absolute left-0 w-5" alt="google logo" />
          <span className="block w-max font-semibold tracking-wide text-gray-700 text-sm transition duration-300 group-hover:text-highlight-primary sm:text-base">Continua con Google</span>
        </div>
      </button>
    </div>
  )
}