
// TODO: Move this to a separate file
interface PrimaryTitleProps {
  text: string;
  center: boolean;
  className: string;
}
export default function PrimaryTitle({ text, center, className = '' }: PrimaryTitleProps): JSX.Element {
  const baseClassName = `text-3xl font-bold tracking-tight text-gray-900 ${className}`;
  const centerClass = 'text-center';
  const finalClassName = center ? `${baseClassName} ${centerClass}` : baseClassName;
  return (
    <h1 className={finalClassName}>{text}</h1>
  )
}