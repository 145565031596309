interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export default function Card({ children, className = '' }: CardProps): JSX.Element {
  return (
    <div className={`bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 ${className}`}>
      {children}
    </div>
  )
}