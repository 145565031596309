import React, { Fragment, useEffect, useState } from "react";
import Formatter from "../../services/formatter";
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition } from "@headlessui/react";
import { ErrorMessage, Field, FieldProps } from "formik";
import countryList from "react-select-country-list";
import { Link } from "react-router-dom";

export function BusinessProfileForm(props: any) {
  const formik = props.formik;
  const [countries, /* setCountries */] = useState(
    countryList().getData().map((country) => {
      return { id: country.value, display_name: country.label }
    })
  );

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
        {/* BUSINESS_NAME */}
        <div>
          <label htmlFor="business_profile.business_name" className="block text-sm font-medium text-gray-700">
            ¿Cuál es el nombre comercial de tu empresa?
          </label>
          <Field name="business_profile.business_name">
            {({ field, form, meta }: FieldProps) => (
              <div className={
                Formatter.classNames(
                  'mt-1',
                  meta.touched && meta.error ?
                    'relative rounded-md shadow-sm' : ''
                )
              }>
                <input
                  type="text"
                  id="business_profile.business_name"
                  className={
                    Formatter.classNames(
                      'block w-full rounded-md sm:text-sm',
                      meta.touched && meta.error ? 
                        'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                        'border-gray-300 shadow-sm focus:border-primary focus:ring-primary'
                    )
                  }
                  placeholder="lendit"
                  aria-describedby={
                    meta.touched && meta.error ?
                      'business-name-error' : 'business-name-description'
                  }
                  aria-invalid={meta.touched && Boolean(meta.error)}
                  {...field}
                />
                {meta.touched && meta.error ? (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                ) : null}
              </div>
            )}
          </Field>
          <ErrorMessage name="business_profile.business_name">
            {message => (
              <p className="mt-2 text-sm text-red-600" id="business-name-error">
                {message}
              </p>
            )}
          </ErrorMessage>
          <p className="mt-2 text-sm text-gray-500" id="business-name-description">
            Con este nombre nos referiremos a tu empresa en lendit (tu razón social la indicarás más adelante). 
            Si tienes una única marca, utiliza ese nombre.
          </p>
        </div>

        {/* RRSS.WEBSITE */}
        <div>
          <label htmlFor="business_profile.rrss.website" className="block text-sm font-medium text-gray-700">
            Página web
          </label>
          <Field name="business_profile.rrss.website">
            {({ field, form, meta }: FieldProps) => (
              <div className="mt-1 relative flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  https://
                </span>
                <input
                  type="text"
                  id="business_profile.rrss.website"
                  className={
                    Formatter.classNames(
                      'block w-full sm:text-sm',
                      meta.touched && meta.error ? 
                        'rounded-none rounded-r-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                        'min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-primary focus:ring-primary'
                    )
                  }
                  placeholder="www.golend.it"
                  aria-describedby={
                    meta.touched && meta.error ? 
                      'rrss-website-error' : undefined
                  }
                  aria-invalid={meta.touched && Boolean(meta.error)}
                  {...field}
                />
                {meta.touched && meta.error ? (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                ) : null}
              </div>
            )}
          </Field>
          <ErrorMessage name="business_profile.rrss.website">
            {message => (
              <p className="mt-2 text-sm text-red-600" id="rrss-website-error">
                {message}
              </p>
            )}
          </ErrorMessage>
        </div>

        {/* RRSS.LINKEDIN */}
        <div>
          <label htmlFor="business_profile.rrss.linkedin" className="block text-sm font-medium text-gray-700">
            LinkedIn
          </label>
          <Field name="business_profile.rrss.linkedin">
            {({ field, form, meta }: FieldProps) => (
              <div className="mt-1 relative flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  https://
                </span>
                <input
                  type="text"
                  id="business_profile.rrss.linkedin"
                  className={
                    Formatter.classNames(
                      'block w-full sm:text-sm',
                      meta.touched && meta.error ? 
                        'rounded-none rounded-r-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                        'min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-primary focus:ring-primary'
                    )
                  }
                  placeholder="www.linkedin.com/company/golendit"
                  aria-describedby={
                    meta.touched && meta.error ? 
                      'rrss-linkedin-error' : undefined
                  }
                  aria-invalid={meta.touched && Boolean(meta.error)}
                  {...field}
                />
                {meta.touched && meta.error ? (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                ) : null}
              </div>
            )}
          </Field>
          <ErrorMessage name="business_profile.rrss.linkedin">
            {message => (
              <p className="mt-2 text-sm text-red-600" id="rrss-linkedin-error">
                {message}
              </p>
            )}
          </ErrorMessage>
        </div>

        {/* RRSS.INSTAGRAM */}
        <div>
          <label htmlFor="business_profile.rrss.instagram" className="block text-sm font-medium text-gray-700">
            Instagram
          </label>
          <Field name="business_profile.rrss.instagram">
            {({ field, form, meta }: FieldProps) => (
              <div className="mt-1 relative flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  @
                </span>
                <input
                  type="text"
                  id="business_profile.rrss.instagram"
                  className={
                    Formatter.classNames(
                      'block w-full sm:text-sm',
                      meta.touched && meta.error ? 
                        'rounded-none rounded-r-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                        'min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-primary focus:ring-primary'
                    )
                  }
                  placeholder="golendit"
                  aria-describedby={
                    meta.touched && meta.error ? 
                      'rrss-instagram-error' : undefined
                  }
                  aria-invalid={meta.touched && Boolean(meta.error)}
                  {...field}
                />
                {meta.touched && meta.error ? (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                ) : null}
              </div>
            )}
          </Field>
          <ErrorMessage name="business_profile.rrss.instagram">
            {message => (
              <p className="mt-2 text-sm text-red-600" id="rrss-instagram-error">
                {message}
              </p>
            )}
          </ErrorMessage>
        </div>

        {/* RRSS.TWITTER */}
        <div>
          <label htmlFor="business_profile.rrss.twitter" className="block text-sm font-medium text-gray-700">
            Twitter
          </label>
          <Field name="business_profile.rrss.twitter">
            {({ field, form, meta }: FieldProps) => (
              <div className="mt-1 relative flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  @
                </span>
                <input
                  type="text"
                  id="business_profile.rrss.twitter"
                  className={
                    Formatter.classNames(
                      'block w-full sm:text-sm',
                      meta.touched && meta.error ? 
                        'rounded-none rounded-r-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                        'min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-primary focus:ring-primary'
                    )
                  }
                  placeholder="golendit"
                  aria-describedby={
                    meta.touched && meta.error ? 
                      'rrss-twitter-error' : undefined
                  }
                  aria-invalid={meta.touched && Boolean(meta.error)}
                  {...field}
                />
                {meta.touched && meta.error ? (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                ) : null}
              </div>
            )}
          </Field>
          <ErrorMessage name="business_profile.rrss.twitter">
            {message => (
              <p className="mt-2 text-sm text-red-600" id="rrss-twitter-error">
                {message}
              </p>
            )}
          </ErrorMessage>
        </div>

        {/* DOCUMENTATION.TAX_ID */}
        <div>
          <label htmlFor="business_profile.documentation.tax_id" className="block text-sm font-medium text-gray-700">RIF de la empresa</label>
          {!formik.values.business_profile.rif_not_exists && (<div className="mt-1">
            <label className="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 cursor-pointer max-w-xs">
              <span className="space-y-1 text-center">
                {formik.values.business_profile.documentation.tax_id ? (
                  <DocumentIcon className="h-10 w-10 mx-auto text-gray-400" aria-hidden="true" />
                ) : (
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="mx-auto w-10 h-10 text-gray-400" 
                    fill="none" 
                    viewBox="0 0 24 24"
                    stroke="currentColor" 
                    strokeWidth="2"
                  >
                    <path 
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                )}
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="business_profile.documentation.tax_id" className="relative cursor-pointer rounded-md bg-white font-medium text-primary focus-within:outline-none hover:text-highlight-primary">
                    {formik.values.business_profile.documentation.tax_id ? 
                      (
                        <p>{formik.values.business_profile.documentation.tax_id.name}</p>
                      ) : (
                        <span>Sube un archivo</span>
                      )
                    }
                    <input 
                      id="business_profile.documentation.tax_id" 
                      name="file-upload" 
                      type="file" 
                      className="sr-only" 
                      onChange={(event) => {
                        if (event.target.files !== null) {
                          formik.setFieldValue('business_profile.documentation.tax_id', event.target.files[0]);
                        }
                      }}
                    />
                  </label>
                </div>
                <p className="text-xs text-gray-500">PDF hasta de 10MB</p>
              </span>
            </label>
          </div>)}
          <div className="relative flex items-start mt-3">
            <div className="flex h-5 items-center">
            <Field name="business_profile.rif_not_exists">
              {({ field, form, meta }: FieldProps) => (
                <input
                  id="business_profile.rif_not_exists"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  {...field}
                />
              )}
            </Field>
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="business_profile.rif_not_exists" className="font-medium text-gray-700">
                No tengo RIF
              </label>
            </div>
          </div>
        </div>

        {/* INTERNATIONAL_BUSINESS_EXISTS */}
        {formik.values.business_profile.rif_not_exists && (<div>
          <label className="block text-sm font-medium text-gray-700">¿Tu empresa está incorporada en un país fuera de Venezuela?</label>
          <fieldset className="mt-4">
            <legend className="sr-only">Empresa incorporada fuera de Venezuela</legend>
            <div className="space-y-4">
              <div className="flex items-center">
                <input
                  id='international-constitution-option-1'
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                  {...formik.getFieldProps('business_profile.international_constitution')}
                  value='yes'
                  checked={formik.values.business_profile.international_constitution === 'yes'}
                />
                <label htmlFor='option-1' className="ml-3 block text-sm font-medium text-gray-700">
                  Si
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id='international-constitution-option-2'
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                  {...formik.getFieldProps('business_profile.international_constitution')}
                  value='no'
                  checked={formik.values.business_profile.international_constitution === 'no'}
                />
                <label htmlFor='option-2' className="ml-3 block text-sm font-medium text-gray-700">
                  No tengo empresa incorporada
                </label>
              </div>
            </div>
          </fieldset>
        </div>)}

        {/* INTERNATIONAL BUSINESS COUNTRY */}
        {formik.values.business_profile.rif_not_exists && formik.values.business_profile.international_constitution === 'yes' && (<div>
          <Listbox value={formik.values.business_profile.country} onChange={(value) => {
              formik.setFieldValue('business_profile.international_business_country', value);
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">¿En qué país está incorporada la empresa?</Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm">
                    <span className="block truncate">{formik.values.business_profile.international_business_country}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {countries.map((country) => (
                        <Listbox.Option
                          key={country.id}
                          className={({ active }) =>
                            Formatter.classNames(
                              active ? 'text-white bg-primary' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={country.display_name}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={Formatter.classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {country.display_name}
                              </span>

                              {selected ? (
                                <span
                                  className={Formatter.classNames(
                                    active ? 'text-white' : 'text-primary',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>)}

        {/* INTERNATIONAL BUSINESS TAX ID */}
        {formik.values.business_profile.rif_not_exists && formik.values.business_profile.international_constitution === 'yes' && (<div>
          <label htmlFor="business_profile.documentation.international_tax_id" className="block text-sm font-medium text-gray-700">Identificación de la empresa</label>
          <div className="mt-1">
            <label className="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 cursor-pointer max-w-xs">
              <span className="space-y-1 text-center">
                {formik.values.business_profile.documentation.international_tax_id ? (
                  <DocumentIcon className="h-10 w-10 mx-auto text-gray-400" aria-hidden="true" />
                ) : (
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="mx-auto w-10 h-10 text-gray-400" 
                    fill="none" 
                    viewBox="0 0 24 24"
                    stroke="currentColor" 
                    strokeWidth="2"
                  >
                    <path 
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                )}
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="business_profile.documentation.international_tax_id" className="relative cursor-pointer rounded-md bg-white font-medium text-primary focus-within:outline-none hover:text-highlight-primary">
                    {formik.values.business_profile.documentation.international_tax_id ? 
                      (
                        <p>{formik.values.business_profile.documentation.international_tax_id.name}</p>
                      ) : (
                        <span>Sube un archivo</span>
                      )
                    }
                    <input 
                      id="business_profile.documentation.international_tax_id" 
                      name="file-upload" 
                      type="file" 
                      className="sr-only" 
                      onChange={(event) => {
                        if (event.target.files !== null) {
                          formik.setFieldValue('business_profile.documentation.international_tax_id', event.target.files[0]);
                        }
                      }}
                    />
                  </label>
                </div>
                <p className="text-xs text-gray-500">PDF hasta de 10MB</p>
              </span>
            </label>
          </div>
        </div>)}

      </div>
      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <Link to='/'>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-primary shadow-sm hover:bg-highlight-primary focus:outline-none focus:ring-2 focus:ring-highlight-primary focus:ring-offset-2 mr-2"
          >
              Guardar para luego
          </button>
        </Link>
        <Link to='/analysis-request/business-activity'>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-highlight-primary focus:outline-none focus:ring-2 focus:ring-highlight-primary focus:ring-offset-2 ml-2"
          >
              Guardar y continuar
          </button>
        </Link>
      </div>
    </div>
  )
}