import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export function CreditAnalysisRequestPreview(props: any) {
  const formik = props.formik;

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
        <h1 className='text-4xl mb-5'>Preview de todo el formulario</h1>
        <p className='text-base'>En construcción</p>

      </div>
      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <Link to='/'>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 py-2 px-4 text-sm font-medium text-primary-700 shadow-sm hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 mr-2"
          >
              Guardar para luego
          </button>
        </Link>
        <button
          type="submit"
          className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 ml-2"
        >
            Enviar solicitud
        </button>
      </div>
    </div>
  )
}