import CreditAnalysisRequestProps from "../../interfaces/credit-analysis-request-props.interface";
import { Navigate, redirect, Route, Routes, useLocation } from "react-router-dom";
import { BusinessProfileForm } from "../business-profile-form/business-profile-form";
import { useEffect, useReducer } from "react";
import FormStep from "../../interfaces/form-step";
import { useRef } from "react";
import FormikSteps from "../formik-steps/formik-steps";
import { Form, Formik, FormikProps } from "formik";
import ICreditAnalysisRequestForm from "../../interfaces/credit-analysis-request-form";
import { BusinessActivityForm } from "../business-activity-form/business-activity-form";
import { CreditAnalysisRequestPreview } from "../credit-analysis-request-preview/credit-analysis-request-preview";
import { saveAnalysisRequest } from "../../services/analysis-requests";
import { creditAnalysisRequestFormikInitialValues } from "./formik-initial-values";
import { creditAnalysisRequestFormikValidationSchema } from "./formik-validation-schema";

export default function CreditAnalysisRequestForm(props: CreditAnalysisRequestProps) {

  const location = useLocation();
  const creditAnalysisRequestFormRef = useRef<FormikProps<ICreditAnalysisRequestForm>>(null);
  const [steps, dispatchSteps] = useReducer((steps: FormStep[]) => {
    return steps.map((step) => {
      
      if (creditAnalysisRequestFormRef.current === null) {
        step.status = 'unvisited';
      }

      if (location.pathname.split('/').pop() === step.href) {
        step.status = 'current';
      } else {
        step.status = 'unvisited';
      }

      return step;
    });
  }, [
    { name: 'Perfil de la empresa', href: 'business-profile', status: 'unvisited', formikName: 'business_profile' },
    { name: 'Actividad de la empresa', href: 'business-activity', status: 'unvisited', formikName: 'business_activity' },
    { name: 'Preview', href: 'business-preview', status: 'unvisited', formikName: undefined },
  ]);

  useEffect(() => {
    dispatchSteps();
  }, [location.pathname]);

  return (

    <div className="flex items-start h-full">
      <FormikSteps steps={steps}/>

      <div className="grow h-full">
        <Formik<ICreditAnalysisRequestForm>
          initialValues={creditAnalysisRequestFormikInitialValues}
          validationSchema={creditAnalysisRequestFormikValidationSchema}
          onSubmit={async (values) => {
            console.log('Empezando submit');
            await saveAnalysisRequest({ form_response_json: values });
            console.log('Terminando submit');
            redirect('/');
            // alert(JSON.stringify(values, null, 2));
          }}
          innerRef={creditAnalysisRequestFormRef}
        >
          {formik => (
            <Form>
              <Routes>
                <Route path='business-profile' 
                  element={<BusinessProfileForm formik={formik} />} 
                />
                <Route path='business-activity' 
                  element={<BusinessActivityForm formik={formik} />} 
                />
                <Route path='business-preview' 
                  element={<CreditAnalysisRequestPreview formik={formik} />} 
                />
                <Route path='*' element={<Navigate to='business-profile' replace />} />
              </Routes>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}