import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { AxiosError } from "axios";
import * as Yup from 'yup';
import Card from "../../components/card/card";
import LenditLogo from "../../components/lendit-logo/lendit-logo";
import MaxWMDContainer from "../../components/max-w-md-container/max-w-md-container";
import PrimaryTitle from "../../components/primary-title/primary-title";
import SeparatorWText from "../../components/separator-w-text/separator-w-text";
import VCenteredContainer from "../../components/v-centered-container/v-centered-container";
import { Button } from "../../components/button/button";
import { FormikField } from "../../components/formik-field/formik-field";
import { GoogleAuthButton } from "../../components/google-auth-button/google-auth-button";
import { OutlinedButton } from "../../components/outlined-button/outlined-button";
import { AppContext } from "../../contexts/app.context";
import { LoginForm } from "../../interfaces/login-form";
import { login } from "../../services/auth";
import { tokenService } from "../../services/token";

export default function Login() {
  const navigate = useNavigate();
  const { setUser } = React.useContext(AppContext);

  useEffect(() => {
    if (tokenService.getLocalAccessToken()) {
      navigate('/');
    }
  }, [navigate]);
  
  return (
    <Formik<LoginForm>
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string()
        .email('Esta no es una dirección de correo válida')
        .required('Necesitas llenar este campo'),
        password: Yup.string()
        .required('Necesitas llenar este campo'),
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const response = await login(values);

        if (response instanceof AxiosError && response.response?.status === 403) {
          setSubmitting(false);
          setErrors({ email: 'El correo electrónico o la contraseña son incorrectos' });
          return;
        } else if ('id' in response) {
          setUser(response);
          if (response.token) {
            tokenService.updateLocalAccesToken(response.token);
          }
          navigate('/');
        }

      }}
    >
      {formik => (
        <Form className="h-full">
          <VCenteredContainer>
            <MaxWMDContainer>
              <LenditLogo size={12} />
              <PrimaryTitle text="Inicia sesión" center className="mt-6" />
            </MaxWMDContainer>

            <MaxWMDContainer className="mt-8">
              <Card className="space-y-7">
                <Field 
                  name="email" 
                  type="email" 
                  label="Correo Electrónico" 
                  placeholder="pepe@golend.it" 
                  component={FormikField} 
                />

                <Field 
                  name="password" 
                  type="password" 
                  label="Contraseña" 
                  component={FormikField} 
                />

                <div>
                  <OutlinedButton href="/password-recovery" text="¿Olvidaste tu contraseña?"/>
                </div>

                <Button text="Iniciar Sesión" type="submit" />

                <div className="text-sm">
                  ¿No tienes cuenta? <OutlinedButton href="/signup" text="Regístrate" />
                </div>

                <SeparatorWText text="O también" className="mt-6" />
                
                <GoogleAuthButton redirectUrl="http://localhost:3000" className="mt-6"/>
              </Card>
            </MaxWMDContainer>
          </VCenteredContainer>
        </Form>
      )}
    </Formik>
  )
}