import { RadioGroup } from "@headlessui/react";
import { ExclamationCircleIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ErrorMessage, Field, FieldProps } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import Formatter from "../../services/formatter";
import { getBusinessActivities } from "../../services/business-activities";
import IBusinessActivity from "../../interfaces/business-activity";
import { Link } from "react-router-dom";

export function BusinessActivityForm(props: any) {
  const formik = props.formik;
  const [searchActivityQuery, setSearchActivityQuery] = useState<string>('');
  const [commercialPlaces, setCommercialPlaces] = useState<undefined | 'zero' | 'one' | 'multiple'>(undefined);
  const [completeFirstActivity, setCompleteFirstActivity] = useState<boolean>(false);
  // const [additionalActivityInfo, setAdditionalActivityInfo] = useState<undefined | 'all-good' | 'no-option-found' | 'multiple-activity'>(undefined);
  const [searchActivityQuery2, setSearchActivityQuery2] = useState<string>('');
  const [commercialPlaces2, setCommercialPlaces2] = useState<undefined | 'zero' | 'one' | 'multiple'>(undefined);
  const [completeSecondActivity, setCompleteSecondActivity] = useState<boolean>(false);
  // const [additionalActivityInfo2, setAdditionalActivityInfo2] = useState<undefined | 'all-good' | 'no-option-found'>(undefined);

  useEffect(() => {
    if (formik.values.business_activity.first_activity?.commercial_places === 0) {
      setCommercialPlaces('zero');
    } else if (formik.values.business_activity.first_activity?.commercial_places === 1) {
      setCommercialPlaces('one');
    } else if (formik.values.business_activity.first_activity?.commercial_places > 1) {
      setCommercialPlaces('multiple');
    }

    if (formik.values.business_activity.second_activity?.commercial_places === 0) {
      setCommercialPlaces('zero');
    } else if (formik.values.business_activity.second_activity?.commercial_places === 1) {
      setCommercialPlaces('one');
    } else if (formik.values.business_activity.second_activity?.commercial_places > 1) {
      setCommercialPlaces('multiple');
    }

    let businessModel = formik.values.business_activity.first_activity?.business_model?.name;
    let commercialPlaces = formik.values.business_activity.first_activity?.commercial_places;
    let distributionModel = formik.values.business_activity.first_activity?.extra_distribution_model;
    let productsImported = formik.values.business_activity.first_activity?.products_imported;

    if (formik.values.business_activity.first_activity !== undefined) {
      setCompleteFirstActivity(true);
      if (businessModel === 'retailer' && commercialPlaces === -1) {
        setCompleteFirstActivity(false);
      } else if (businessModel === 'manufacturer' && distributionModel === undefined) {
        setCompleteFirstActivity(false);
      } else if (businessModel === 'distribution' && productsImported === undefined) {
        setCompleteFirstActivity(false);
      }
    } else {
      setCompleteFirstActivity(false);
    }

    businessModel = formik.values.business_activity.second_activity?.business_model.name;
    commercialPlaces = formik.values.business_activity.second_activity?.commercial_places;
    distributionModel = formik.values.business_activity.second_activity?.extra_distribution_model;
    productsImported = formik.values.business_activity.second_activity?.products_imported;

    if (formik.values.business_activity.second_activity !== undefined) {
      setCompleteSecondActivity(true);
      if (businessModel === 'retailer' && commercialPlaces === -1) {
        setCompleteSecondActivity(false);
      } else if (businessModel === 'manufacturer' && distributionModel === undefined) {
        setCompleteSecondActivity(false);
      } else if (businessModel === 'distribution' && productsImported === undefined) {
        setCompleteSecondActivity(false);
      }
    } else {
      setCompleteSecondActivity(false);
    }

  }, []);

  useEffect(() => {
    const businessModel = formik.values.business_activity.first_activity?.business_model?.name;
    const commercialPlaces = formik.values.business_activity.first_activity?.commercial_places;
    const distributionModel = formik.values.business_activity.first_activity?.extra_distribution_model;
    const productsImported = formik.values.business_activity.first_activity?.products_imported;

    if (formik.values.business_activity.first_activity !== undefined) {
      setCompleteFirstActivity(true);
      if (businessModel === 'retailer' && commercialPlaces === -1) {
        setCompleteFirstActivity(false);
      } else if (businessModel === 'manufacturer' && distributionModel === undefined) {
        setCompleteFirstActivity(false);
      } else if (businessModel === 'distribution' && productsImported === undefined) {
        setCompleteFirstActivity(false);
      }
    } else {
      setCompleteFirstActivity(false);
    }

  }, [formik.values.business_activity.first_activity]);

  useEffect(() => {
    const businessModel = formik.values.business_activity.second_activity?.business_model.name;
    const commercialPlaces = formik.values.business_activity.second_activity?.commercial_places;
    const distributionModel = formik.values.business_activity.second_activity?.extra_distribution_model;
    const productsImported = formik.values.business_activity.second_activity?.products_imported;

    if (formik.values.business_activity.second_activity !== undefined) {
      setCompleteSecondActivity(true);
      if (businessModel === 'retailer' && commercialPlaces === -1) {
        setCompleteSecondActivity(false);
      } else if (businessModel === 'manufacturer' && distributionModel === undefined) {
        setCompleteSecondActivity(false);
      } else if (businessModel === 'distribution' && productsImported === undefined) {
        setCompleteSecondActivity(false);
      }
    } else {
      setCompleteSecondActivity(false);
    }

  }, [formik.values.business_activity.second_activity]);

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
        {/* BUSINESS ACTIVITY */}
        <div>
          <label htmlFor="searchbar" className="block text-sm font-medium text-gray-700">
            ¿A qué se dedica tu empresa principalmente?
          </label>
          {formik.values.business_activity.first_activity === undefined && (<div className="relative mt-1 rounded-md shadow-sm mb-3">
            <input
              type="text"
              name="searchbar"
              id="searchbar"
              className="block w-full rounded-md border-gray-300 pr-10 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder='Distribución de alimentos, servicios de ingenieria, etc'
              value={searchActivityQuery}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchActivityQuery(event.target.value)}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>)}
          <Fragment>
            {formik.values.business_activity.first_activity === undefined && (<RadioGroup value={formik.values.business_activity.first_activity} onChange={(activity: IBusinessActivity) => { formik.setFieldValue('business_activity.first_activity', activity); }}>
              <RadioGroup.Label className="sr-only"> Actividad de la empresa </RadioGroup.Label>
              <div className="space-y-4">
                {getBusinessActivities(searchActivityQuery).map((sector) => (
                  <RadioGroup.Option
                    key={sector.display_name}
                    value={sector}
                    className={({ checked, active }) =>
                      Formatter.classNames(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                        'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <Fragment>
                        <span className="flex items-center">
                          <span className="flex flex-col text-sm">
                            <RadioGroup.Label as="span" className="font-medium text-gray-900">
                              {sector.display_name}
                            </RadioGroup.Label>
                            <RadioGroup.Description as="span" className="text-gray-500">
                              <span className="block sm:inline">
                                {sector.description}
                              </span>
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <span
                          className={Formatter.classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-primary-500' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </Fragment>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>)}
            {formik.values.business_activity.first_activity !== undefined && (
              <div 
                onClick={() => { 
                  formik.setFieldValue('business_activity.first_activity.products_imported', undefined);
                  formik.setFieldValue('business_activity.first_activity.extra_distribution_model', undefined);
                  formik.setFieldValue('business_activity.first_activity.commercial_places', -1);
                  formik.setFieldValue('business_activity.first_activity.additional_info', undefined);
                  formik.setFieldValue('business_activity.first_activity', undefined);
                  setSearchActivityQuery('');
                  setCommercialPlaces(undefined);
                }}
                className="border-gray-300 relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between mt-4"
              >
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <span className="font-medium text-gray-900">
                      {formik.values.business_activity.first_activity?.display_name}
                    </span>
                    <span className="text-gray-500">
                      <span className="block sm:inline">
                        {formik.values.business_activity.first_activity?.description}
                      </span>
                    </span>
                  </span>
                </span>
                <span
                  className={Formatter.classNames(
                    'border',
                    'border-primary-500',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </div>
            )}
            {typeof formik.values.business_activity.first_activity?.business_model === 'object' && formik.values.business_activity.first_activity?.business_model.name === 'distribution' && (
              <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700">¿Los productos son importados?</label>
                <fieldset className="mt-4">
                  <legend className="sr-only">¿Productos importados?</legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id='products-imported-option-1'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.first_activity.products_imported')}
                        value='local'
                        checked={formik.values.business_activity.first_activity?.products_imported === 'local'}
                      />
                      <label htmlFor='products-imported-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                        No, todos los productos son locales
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='products-imported-option-2'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.first_activity.products_imported')}
                        value='mostly-imported'
                        checked={formik.values.business_activity.first_activity.products_imported === 'mostly-imported'}
                      />
                      <label htmlFor='products-imported-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                        Sí, mas del 50% de los productos son importados
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='products-imported-option-3'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.first_activity.products_imported')}
                        value='mostly-local'
                        checked={formik.values.business_activity.first_activity?.products_imported === 'mostly-local'}
                      />
                      <label htmlFor='products-imported-option-3' className="ml-3 block text-sm font-medium text-gray-700">
                        Menos del 50% de los productos son importados
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {typeof formik.values.business_activity.first_activity?.business_model === 'object' && formik.values.business_activity.first_activity?.business_model.name === 'manufacturer' && (
              <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700">¿También es distribuidora?</label>
                <fieldset className="mt-4">
                  <legend className="sr-only">¿Distribuidora?</legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id='distribution-model-option-1'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.first_activity.extra_distribution_model')}
                        value={'yes'}
                        checked={formik.values.business_activity.first_activity.extra_distribution_model === 'yes'}
                      />
                      <label htmlFor='distribution-model-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                        Si
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='distribution-model-option-2'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.first_activity.extra_distribution_model')}
                        value={'no'}
                        checked={formik.values.business_activity.first_activity?.extra_distribution_model === 'no'}
                      />
                      <label htmlFor='distribution-model-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                        No
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {typeof formik.values.business_activity.first_activity?.business_model === 'object' && formik.values.business_activity.first_activity?.business_model.name === 'retailer' && (
              <Fragment>
                <div className="mt-5">
                  <label className="block text-sm font-medium text-gray-700">¿Cuántos establecimientos tiene?</label>
                  <fieldset className="mt-4">
                    <legend className="sr-only">¿Cuántos establecimientos?</legend>
                    <div className="space-y-4">
                      <div className="flex items-center">
                        <input
                          id='commercial-places-option-1'
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                          value={'zero'}
                          name='retailer-commercial-places'
                          onChange={() => { setCommercialPlaces('zero'); formik.setFieldValue('business_activity.first_activity.commercial_places', 0) }}
                          checked={commercialPlaces === 'zero' }
                        />
                        <label htmlFor='commercial-places-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                          Ninguno
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id='commercial-places-option-2'
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                          value={'one'}
                          name='retailer-commercial-places'
                          onChange={() => { setCommercialPlaces('one'); formik.setFieldValue('business_activity.first_activity.commercial_places', 1) }}
                          checked={commercialPlaces === 'one'}
                        />
                        <label htmlFor='commercial-places-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                          Solo uno
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id='commercial-places-option-3'
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                          value={'multiple'}
                          name='retailer-commercial-places'
                          onChange={() => { setCommercialPlaces('multiple'); if (formik.values.business_activity.first_activity?.commercial_places === -1) { formik.setFieldValue('business_activity.first_activity.commercial_places', 2) } }}
                          checked={commercialPlaces === 'multiple'}
                        />
                        <label htmlFor='commercial-places-option-3' className="ml-3 block text-sm font-medium text-gray-700">
                          Varios
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                {commercialPlaces === 'multiple' && (<div className="mt-5">
                  <Field name="business_activity.first_activity.commercial_places">
                    {({ field, form, meta }: FieldProps) => (
                      <div className={
                        Formatter.classNames(
                          'mt-1',
                          meta.touched && meta.error ?
                            'relative rounded-md shadow-sm' : ''
                        )
                      }>
                        <input
                          type="number"
                          id="business_activity.first_activity.commercial_places"
                          className={
                            Formatter.classNames(
                              'block w-full rounded-md sm:text-sm',
                              meta.touched && meta.error ? 
                                'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                                'border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500'
                            )
                          }
                          aria-describedby={
                            meta.touched && meta.error ?
                              'commercial-places-error' : undefined
                          }
                          aria-invalid={meta.touched && Boolean(meta.error)}
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Field>
                  <ErrorMessage name="business_activity.first_activity.commercial_places">
                    {message => (
                      <p className="mt-2 text-sm text-red-600" id="commercial-places-error">
                        {message}
                      </p>
                    )}
                  </ErrorMessage>
                </div>)}
              </Fragment>
            )}
            {completeFirstActivity && (
              <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700">¿Quieres agregar algo referente a la actividad de tu empresa?</label>
                <fieldset className="mt-4">
                  <legend className="sr-only">¿Algo más de la actividad de tu empresa?</legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id='additional-activity-info-option-1'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        name='additional-activity-info'
                        value={'all-good'}
                        onChange={() => { formik.setFieldValue('business_activity.first_activity.additional_info', 'all-good'); }}
                        checked={formik.values.business_activity.first_activity?.additional_info === 'all-good'}
                      />
                      <label htmlFor='additional-activity-info-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                        No, ya puedo continuar
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='additional-activity-info-option-2'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        name='additional-activity-info'
                        value={'no-option-found'}
                        onChange={() => { formik.setFieldValue('business_activity.first_activity.additional_info', 'no-option-found'); }}
                        checked={formik.values.business_activity.first_activity?.additional_info === 'no-option-found'}
                      />
                      <label htmlFor='additional-activity-info-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                        No encontré una opción que defina adecuadamente la actividad de mi empresa
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='additional-activity-info-option-3'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        name='additional-activity-info'
                        value={'multiple-activity'}
                        onChange={() => { formik.setFieldValue('business_activity.first_activity.additional_info', 'multiple-activity'); }}
                        checked={formik.values.business_activity.first_activity?.additional_info === 'multiple-activity'}
                      />
                      <label htmlFor='additional-activity-info-option-3' className="ml-3 block text-sm font-medium text-gray-700">
                        Mi empresa realiza otra actividad más
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
          </Fragment>
        </div>
        {formik.values.business_activity.first_activity?.additional_info === 'multiple-activity' && (<div>
          <label htmlFor="searchbar2" className="block text-sm font-medium text-gray-700">
            ¿A qué otra actividad se dedica tu empresa?
          </label>
          {formik.values.business_activity.second_activity === undefined && (<div className="relative mt-1 rounded-md shadow-sm mb-3">
            <input
              type="text"
              name="searchbar2"
              id="searchbar2"
              className="block w-full rounded-md border-gray-300 pr-10 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder='Distribución de alimentos, servicios de ingenieria, etc'
              value={searchActivityQuery2}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchActivityQuery2(event.target.value)}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>)}
          <Fragment>
            {formik.values.business_activity.second_activity === undefined && (<RadioGroup value={formik.values.business_activity.second_activity} onChange={(activity: IBusinessActivity) => { formik.setFieldValue('business_activity.second_activity', activity); }}>
              <RadioGroup.Label className="sr-only"> Actividad de la empresa </RadioGroup.Label>
              <div className="space-y-4">
                {getBusinessActivities(searchActivityQuery2).map((sector) => (
                  <RadioGroup.Option
                    key={sector.display_name}
                    value={sector}
                    className={({ checked, active }) =>
                      Formatter.classNames(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                        'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <Fragment>
                        <span className="flex items-center">
                          <span className="flex flex-col text-sm">
                            <RadioGroup.Label as="span" className="font-medium text-gray-900">
                              {sector.display_name}
                            </RadioGroup.Label>
                            <RadioGroup.Description as="span" className="text-gray-500">
                              <span className="block sm:inline">
                                {sector.description}
                              </span>
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <span
                          className={Formatter.classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-primary-500' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </Fragment>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>)}
            {formik.values.business_activity.second_activity !== undefined && (
              <div 
                // onClick={() => { formik.setFieldValue('business_activity.first_activity', undefined); setSearchActivityQuery2('') }}
                onClick={() => { 
                  formik.setFieldValue('business_activity.second_activity.products_imported', undefined);
                  formik.setFieldValue('business_activity.second_activity.extra_distribution_model', undefined);
                  formik.setFieldValue('business_activity.second_activity.commercial_places', -1);
                  formik.setFieldValue('business_activity.second_activity.additional_info', undefined);
                  formik.setFieldValue('business_activity.second_activity', undefined);
                  setSearchActivityQuery2('');
                  setCommercialPlaces2(undefined);
                }}
                className="border-gray-300 relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between mt-4"
              >
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <span className="font-medium text-gray-900">
                      {formik.values.business_activity.second_activity.display_name}
                    </span>
                    <span className="text-gray-500">
                      <span className="block sm:inline">
                        {formik.values.business_activity.second_activity.description}
                      </span>
                    </span>
                  </span>
                </span>
                <span
                  className={Formatter.classNames(
                    'border',
                    'border-primary-500',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </div>
            )}
            {typeof formik.values.business_activity.second_activity?.business_model === 'object' && formik.values.business_activity.second_activity?.business_model.name === 'distribution' && (
              <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700">¿Los productos son importados?</label>
                <fieldset className="mt-4">
                  <legend className="sr-only">¿Productos importados?</legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id='products-imported2-option-1'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.second_activity.products_imported')}
                        value='local'
                        checked={formik.values.business_activity.second_activity.products_imported === 'local'}
                      />
                      <label htmlFor='products-imported2-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                        No, todos los productos son locales
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='products-imported2-option-2'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.second_activity.products_imported')}
                        value='mostly-imported'
                        checked={formik.values.business_activity.second_activity.products_imported === 'mostly-imported'}
                      />
                      <label htmlFor='products-imported2-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                        Sí, mas del 50% de los productos son importados
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='products-imported2-option-3'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.second_activity.products_imported')}
                        value='mostly-local'
                        checked={formik.values.business_activity.second_activity.products_imported === 'mostly-local'}
                      />
                      <label htmlFor='products-imported2-option-3' className="ml-3 block text-sm font-medium text-gray-700">
                        Menos del 50% de los productos son importados
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {typeof formik.values.business_activity.second_activity?.business_model === 'object' && formik.values.business_activity.second_activity?.business_model.name === 'manufacturer' && (
              <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700">¿También es distribuidora?</label>
                <fieldset className="mt-4">
                  <legend className="sr-only">¿Distribuidora?</legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id='distribution-model2-option-1'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.second_activity.extra_distribution_model')}
                        value={'yes'}
                        checked={formik.values.business_activity.second_activity.extra_distribution_model === 'yes'}
                      />
                      <label htmlFor='distribution-model2-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                        Si
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='distribution-model-option-2'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        {...formik.getFieldProps('business_activity.second_activity.extra_distribution_model')}
                        value={'no'}
                        checked={formik.values.business_activity.second_activity.extra_distribution_model === 'no'}
                      />
                      <label htmlFor='distribution-model2-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                        No
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {typeof formik.values.business_activity.second_activity?.business_model === 'object' && formik.values.business_activity.second_activity?.business_model.name === 'retailer' && (
              <Fragment>
                <div className="mt-5">
                  <label className="block text-sm font-medium text-gray-700">¿Cuántos establecimientos tiene?</label>
                  <fieldset className="mt-4">
                    <legend className="sr-only">¿Cuántos establecimientos?</legend>
                    <div className="space-y-4">
                      <div className="flex items-center">
                        <input
                          id='commercial-places2-option-1'
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                          value={'zero'}
                          name='retailer-commercial-places2'
                          onChange={() => { setCommercialPlaces2('zero'); formik.setFieldValue('business_activity.second_activity.commercial_places', 0) }}
                          checked={commercialPlaces2 === 'zero' }
                        />
                        <label htmlFor='commercial-places2-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                          Ninguno
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id='commercial-places2-option-2'
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                          value={'one'}
                          name='retailer-commercial-places2'
                          onChange={() => { setCommercialPlaces2('one'); formik.setFieldValue('business_activity.second_activity.commercial_places', 1) }}
                          checked={commercialPlaces2 === 'one'}
                        />
                        <label htmlFor='commercial-places2-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                          Solo uno
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id='commercial-places2-option-3'
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                          value={'multiple'}
                          name='retailer-commercial-places'
                          onChange={() => { setCommercialPlaces2('multiple'); if (formik.values.business_activity.second_activity.commercial_places === -1) { formik.setFieldValue('business_activity.second_activity.commercial_places', 2) } }}
                          checked={commercialPlaces2 === 'multiple'}
                        />
                        <label htmlFor='commercial2-places-option-3' className="ml-3 block text-sm font-medium text-gray-700">
                          Varios
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                {commercialPlaces === 'multiple' && (<div className="mt-5">
                  <Field name="business_activity.second_activity.commercial_places">
                    {({ field, form, meta }: FieldProps) => (
                      <div className={
                        Formatter.classNames(
                          'mt-1',
                          meta.touched && meta.error ?
                            'relative rounded-md shadow-sm' : ''
                        )
                      }>
                        <input
                          type="number"
                          id="business_activity.second_activity.commercial_places"
                          className={
                            Formatter.classNames(
                              'block w-full rounded-md sm:text-sm',
                              meta.touched && meta.error ? 
                                'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' :
                                'border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500'
                            )
                          }
                          aria-describedby={
                            meta.touched && meta.error ?
                              'commercial-places2-error' : undefined
                          }
                          aria-invalid={meta.touched && Boolean(meta.error)}
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Field>
                  <ErrorMessage name="business_activity.second_activity.commercial_places">
                    {message => (
                      <p className="mt-2 text-sm text-red-600" id="commercial-places2-error">
                        {message}
                      </p>
                    )}
                  </ErrorMessage>
                </div>)}
              </Fragment>
            )}
            {completeSecondActivity && (
              <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700">¿Quieres agregar algo referente a la actividad de tu empresa?</label>
                <fieldset className="mt-4">
                  <legend className="sr-only">¿Algo más de la actividad de tu empresa?</legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id='additional-activity-info2-option-1'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        name='additional-activity-info2'
                        value={'all-good'}
                        onChange={() => { formik.setFieldValue('business_activity.second_activity.additional_info', 'all-good'); }}
                        checked={formik.values.business_activity.second_activity?.additional_info === 'all-good'}
                      />
                      <label htmlFor='additional-activity-info2-option-1' className="ml-3 block text-sm font-medium text-gray-700">
                        No, ya puedo continuar
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='additional-activity-info2-option-2'
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                        name='additional-activity-info2'
                        value={'no-option-found'}
                        onChange={() => { formik.setFieldValue('business_activity.second_activity.additional_info', 'no-option-found'); }}
                        checked={formik.values.business_activity.second_activity?.additional_info === 'no-option-found'}
                      />
                      <label htmlFor='additional-activity-info2-option-2' className="ml-3 block text-sm font-medium text-gray-700">
                        No encontré una opción que defina adecuadamente la actividad de mi empresa
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
          </Fragment>
        </div>)}
        {(formik.values.business_activity.first_activity?.additional_info === 'no-option-found' || formik.values.business_activity.second_activity?.additional_info === 'no-option-found') && (
          <div>
            <label htmlFor="business_activity.raw_description" className="block text-sm font-medium text-gray-700">
              Explícanos qué haces
            </label>
            <Field name="business_activity.raw_description">
              {({ field, form, meta }: FieldProps) => (
                <div className="mt-1">
                  <textarea
                    rows={4}
                    id="business_activity.raw_description"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    {...field}
                  />
                </div>
              )}
            </Field>
          </div>
        )}
        {completeFirstActivity && completeSecondActivity && (formik.values.business_activity.first_activity?.additional_info === 'all-good' || formik.values.business_activity.second_activity?.additional_info === 'all-good' || (formik.values.business_activity.raw_description !== '')) && (
          <div>
            <label htmlFor="business_profile.history" className="block text-sm font-medium text-gray-700">
              ¿Quieres contarnos brevemente la historia de tu empresa?
            </label>
            <Field name="business_profile.history">
              {({ field, form, meta }: FieldProps) => (
                <div className="mt-1">
                  <textarea
                    rows={4}
                    id="business_profile.history"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <p className="mt-2 text-sm text-gray-500" id="business-name-description">
              Puedes dejarlo vacío si deseas
            </p>
          </div>
        )}
        {formik.values.business_profile.history !== '' && (
          <div>
            <label htmlFor="business_profile.success_factor" className="block text-sm font-medium text-gray-700">
              ¿Qué ha hecho que tu empresa llegue a donde esté?
            </label>
            <Field name="business_profile.success_factor">
              {({ field, form, meta }: FieldProps) => (
                <div className="mt-1">
                  <textarea
                    rows={4}
                    id="business_profile.success_factor"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <p className="mt-2 text-sm text-gray-500" id="business-name-description">
              Puedes dejarlo vacío si deseas
            </p>
          </div>
        )}
      </div>
      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <Link to='/'>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 py-2 px-4 text-sm font-medium text-primary-700 shadow-sm hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 mr-2"
          >
              Guardar para luego
          </button>
        </Link>
        <Link to='/analysis-request/business-preview'>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 ml-2"
          >
              Guardar y continuar
          </button>
        </Link>
      </div>
    </div>
  )
}