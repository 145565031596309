
type OutlinedButtonProps = {
  text: string;
  href: string;
}

export function OutlinedButton({ text, href, ...rest }: OutlinedButtonProps): JSX.Element {
  return (
    <a href={href} className="text-sm font-medium text-primary hover:text-highlight-primary">
      {text}
    </a>
  )
}