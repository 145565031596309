import React, { Fragment } from 'react';
import Select from '../../components/select/select';
import LoanRequestFormProps from '../../interfaces/loan-request-form-props';
import LoanRequestFormState from '../../interfaces/loan-request-form-state';
import countryList from 'react-select-country-list';
import Country from '../../interfaces/country';
import IconRoundedButton from '../../components/icon-rounded-button/icon-rounded-button';
import { PlusIcon as PlusIconOutline } from '@heroicons/react/24/outline';
import IconOutlinedButton from '../../components/icon-outlined-button/icon-outlined-button';
import { XMarkIcon } from '@heroicons/react/20/solid';
import TextField from '../../components/text-field/text-field';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function LoanRequestForm() {
  const formik = useFormik({
    initialValues: {
      first_name: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(32, 'Debe tener 32 caracteres o menos')
        .required('El nombre de la empresa es obligatorio')
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });
  return (
    <div className='flex flex-col max-w-7xl mx-auto pt-5'>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Datos de la empresa</h3>
              <p className="mt-1 text-sm text-gray-600">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                labore et dolore magna aliqua. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit. 
                Enim blandit volutpat maecenas volutpat blandit. Odio euismod lacinia at quis risus sed vulputate.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form onSubmit={formik.handleSubmit}>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <TextField 
                        label='Nombre comercial de la empresa'
                        name='first_name'
                        placeholder='e.g. Empresas Polar'
                        hint='Con este nombre nos referiremos a tu empresa en lendit (tu razón social la indicarás más adelante). Si tienes una única marca, utiliza ese nombre.'
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                      />
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Página web
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                          https://
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                          placeholder="www.example.com"
                        />
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Linkedin
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                          https://linkedin.com/
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                          placeholder="pedroperez"
                        />
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Instagram
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                          @
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                          placeholder="miusuario"
                        />
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Twitter
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                          @
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                          placeholder="miusuario"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Cover photo</label>
                    {/* <label
                      className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                      <span className="flex items-center space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                              stroke="currentColor" strokeWidth="2">
                              <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                          </svg>
                          <span className="font-medium text-gray-600">
                              Drop files to Attach, or
                              &nbsp;<span className="text-blue-600 underline">browse</span>
                          </span>
                      </span>
                      <input type="file" name="file_upload" className="hidden" />
                    </label> */}
                    <label className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 cursor-pointer">
                      <span className="space-y-1 text-center">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="mx-auto w-10 h-10 text-gray-400" 
                          fill="none" 
                          viewBox="0 0 24 24"
                          stroke="currentColor" 
                          strokeWidth="2"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" 
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-primary focus-within:outline-none hover:text-primary"
                          >
                            <span>Upload a file</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
