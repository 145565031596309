import * as Yup from 'yup';
import ICreditAnalysisRequestForm from "../../interfaces/credit-analysis-request-form";

const creditAnalysisRequestFormikValidationSchema: Yup.SchemaOf<ICreditAnalysisRequestForm> = Yup.object({
  business_profile: Yup.object({
    business_name: Yup.string()
    .required('Esta información es requerida.')
    .min(4, 'Debe contener más de 4 caracteres'),
    rrss: Yup.object({
      website: Yup.string(),
      linkedin: Yup.string(),
      twitter: Yup.string(),
      instagram: Yup.string(),
    }),
    rif_not_exists: Yup.boolean().required('Esta información es requerida.'),
    international_constitution: Yup.mixed().oneOf(['yes', 'no']),
    international_business_country: Yup.string(),
    national: Yup.boolean().required('Esta información es requerida.'),
    country: Yup.string().required('Esta información es requerida.'),
    documentation: Yup.object({
      tax_id: Yup.mixed().test(
        'file-size', 
        'File size is too large', 
        value => (value.size <= 8192)
      ),
      international_tax_id: Yup.mixed().test(
        'file-size', 
        'File size is too large', 
        value => (value.size <= 8192)
      ),
    }),

  }),
  business_activity: Yup.object({
    first_activity: Yup.object({
      display_name: Yup.string().required('Esta información es requerida.'),
      description: Yup.string(),
      tags: Yup.array(Yup.string()),
      sub_industry: Yup.string(),
      business_model: Yup.string(),
      extra_distribution_model: Yup.mixed().oneOf(['yes', 'no']),
      products_imported: Yup.mixed().oneOf(['local', 'mostly-imported', 'mostly-local']),
      commercial_places: Yup.number(),
      additional_info: Yup.mixed().oneOf(['all-good', 'no-option-found', 'multiple-activity']),
    }).optional().default(undefined),
    second_activity: Yup.object({
      display_name: Yup.string().required('Esta información es requerida.'),
      description: Yup.string(),
      tags: Yup.array(Yup.string()),
      sub_industry: Yup.string(),
      business_model: Yup.string(),
      extra_distribution_model: Yup.mixed().oneOf(['yes', 'no']),
      products_imported: Yup.mixed().oneOf(['local', 'mostly-imported', 'mostly-local']),
      commercial_places: Yup.number(),
      additional_info: Yup.mixed().oneOf(['all-good', 'no-option-found', 'multiple-activity']),
    }).optional().default(undefined),
    history: Yup.string().optional(),
    success_factor: Yup.string().optional(),
    raw_description: Yup.string().optional(),
  }),
});

export { creditAnalysisRequestFormikValidationSchema };
