import IBusinessActivity from "../interfaces/business-activity";
import Fuse from "fuse.js";
import { getBusinessModels } from "./business-models";
import { getIndustryClassifications } from "./industry-classifications";

const businessActivities: IBusinessActivity[] = [{
  display_name: "Perforación de pozos petroleros o de gas",
  description: "Contratistas, o propietarios de torres de perforación",
  tags: ["petroleo", "gas", "perforacion"],
  sub_industry: "10101010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos o suministros para la industria petrolera",
  description: "",
  tags: ["petroleo", "gas", "equipos", "suministros"],
  sub_industry: "10101020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Ofrece servicios especializados para empresas de perforación petrolera",
  description: "",
  tags: ["petroleo", "gas", "servicios"],
  sub_industry: "10101020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Petrolera",
  description: "Integra múltiples actividades entre perforación, explotación, refinado, distribución y comercialización, productos químicos",
  tags: ["petroleo", "gas", "petrolera", "perforacion", "explotacion", "exploracion", "refinacion", "derivados", "distribuidor", "productor"],
  sub_industry: "10102010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Refinería y/o distribución de productos refinados",
  description: "",
  tags: ["petroleo", "gas", "refinacion", "refineria", "refinado", "distribucion"],
  sub_industry: "10102030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Almacenamiento y transporte de petróleo y/o gas",
  description: "Dedicada exclusivamente a esta actividad",
  tags: ["petroleo", "gas", "almacenamiento", "almacenaje", "transporte"],
  sub_industry: "10102040",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Proyectos de ingeniería",
  description: "",
  tags: ["ingenieria", "proyectos", "servicio"],
  sub_industry: "20103010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Indsutria del carbón",
  description: "",
  tags: ["carbon"],
  sub_industry: "10102050",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Farmacia, droguería",
  description: "",
  tags: ["farmacia", "drogueria"],
  sub_industry: "30101010",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Supermercado",
  description: "",
  tags: ["supermercado", "alimentos", "mercado"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Hipermercado",
  description: "",
  tags: ["supermercado", "alimentos", "mercado", "hipermercado"],
  sub_industry: "30101040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Abasto, Bodega",
  description: "",
  tags: ["abasto", "bodega", "mercado", "alimentos"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tiendas de conveniencia",
  description: "",
  tags: ["supermercado", "alimentos", "mercado", "conveniencia", "tienda"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Ensambla / fabrica vehículos",
  description: "",
  tags: ["ensambladora", "carros", "vehiculos", "autobuses", "camiones", "fabrica"],
  sub_industry: "25102010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Ensambla / fabrica motos",
  description: "",
  tags: ["ensambladora", "carros", "vehiculos", "motos", "fabrica"],
  sub_industry: "25102020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica autopartes, repuestos",
  description: "",
  tags: ["partes", "auto", "repuestos", "vehiculos", "carros", "camiones", "autobuses", "tractores", "fabrica"],
  sub_industry: "25101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuye autopartes, repuestos",
  description: "",
  tags: ["partes", "auto", "repuestos", "vehiculos", "carros", "camiones", "autobuses", "tractores", "distribucion"],
  sub_industry: "25501010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de autopartes, repuestos",
  description: "",
  tags: ["partes", "auto", "repuestos", "vehiculos", "carros", "camiones", "autobuses", "tractores", "tienda"],
  sub_industry: "25504050",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica materiales de construcción",
  description: "Arena, arcilla, yeso, cal, conglomerado, cemento, hormigón, ladrillos",
  tags: ["construccion", "materiales", "acero", "hierro", "concreto", "vigas", "cabillas", "fabrica", "arena", "arcilla", "yeso", "cal", "conglomerado", "cemento", "hormigón", "ladrillos"],
  sub_industry: "15102010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuye materiales de construcción",
  description: "",
  tags: ["construccion", "materiales", "acero", "hierro", "concreto", "vigas", "cabillas", "distribucion"],
  sub_industry: "25501010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de materiales de construcción",
  description: "",
  tags: ["construccion", "materiales", "acero", "hierro", "concreto", "vigas", "cabillas", "tienda"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Ferretería",
  description: "",
  tags: ["ferreteria", "reparaciones", "hogar", "construccion", "electricos"],
  sub_industry: "25504030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Bodegón",
  description: "",
  tags: ["comida", "bodegon", "importados", "lujo", "delicateses", "licores", "alimentos", "tienda", "retail"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Marketplace/plataforma online",
  description: "",
  tags: ["marketplace", "plataforma", "online", "digital", "ecommerce", "store", "tienda"],
  sub_industry: "25502020",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "e-commerce",
  description: "",
  tags: ["plataforma", "online", "digital", "ecommerce", "store", "tienda"],
  sub_industry: "25502020",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica ropa",
  description: "",
  tags: ["ropa", "moda", "joyas", "lujo", "joyeria", "prendas", "pantalones", "camisas", "interior", "lingerie", "franelas", "medias", "chaquetas", "vestir", "vestimenta", "fabrica", "manufactura", "produccion"],
  sub_industry: "25203010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica joyas, u orfebrería",
  description: "",
  tags: ["joyas", "orfebreria", "lujo", "prendas", "ropa", "fabrica"],
  sub_industry: "25203010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica zapatos",
  description: "",
  tags: ["zapatos", "zapateria", "ropa", "moda", "prendas", "fabrica"],
  sub_industry: "25203020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica muebles y/o artículos de hogar",
  description: "",
  tags: ["muebles", "hogar", "articulos", "sofa", "cama", "closet", "mesa", "silla", "fabrica"],
  sub_industry: "25201020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce farmacéuticos",
  description: "Laboratorios farmacéuticos",
  tags: ["laboratorio", "farmacia", "quimico", "quimica", "productor", "produccion"],
  sub_industry: "35202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Herramientas y servcios para ciencias biológicas",
  description: "",
  tags: ["biologia", "vida", "ciencia", "servicio", "herramienta"],
  sub_industry: "35203010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce alimentos cárnicos y/o embutidos",
  description: "",
  tags: ["procesados", "embutidos", "embutidores", "carne", "carnicos", "agroindustrial", "productor", "charcuteria"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce alimentos enlatados",
  description: "",
  tags: ["enlatados", "alimentos", "procesador", "planta"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce alimentos agrícolas de consumo masivo",
  description: "Pastas, harinas, granos, cereales,",
  tags: ["alimentos", "consumo", "masivo", "pastas", "harinas", "granos", "cereales", "cafe"],
  sub_industry: "30202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Producción avícola",
  description: "",
  tags: ["huevos", "pollos", "avicola"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce jugos naturales de frutas, pulpas, concentrados.",
  description: "",
  tags: ["jugos", "frutas", "naturales", "pulpas", "concentrados", "fabrica", "planta", "productor"],
  sub_industry: "30202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce chocolate y/o productos de confitería",
  description: "",
  tags: ["chocolate", "cacao", "confiteria", "galletas", "galleteria"],
  sub_industry: "30202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce alimentos preparados para animales",
  description: "",
  tags: ["animales", "mascotas", "alimento"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce alimentos de pescado y mariscos",
  description: "",
  tags: ["pescados", "mariscos"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Pesca",
  description: "",
  tags: ["pesca"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Camaronera",
  description: "",
  tags: ["camaronera", "camarones"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce bebidas no alcohólicas",
  description: "Refresco, te, agua mineral, bebidas carbonatadas",
  tags: ["bebidas", "alcohol", "alcoholica", "alimentos", "productor", "comida", "refresco", "te", "gaseosa", "agua", "gasificada", "soft", "drinks", "jugos"],
  sub_industry: "30201030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce lácteos",
  description: "Leche líquida, en polvo, quesos, mantequilla, yogurt, suero",
  tags: ["leche", "lacteos", "queso", "yogurt", "mantequilla", "yogurt", "suero"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuidora de alimentos",
  description: "",
  tags: ["alimentos", "distribucion", "distribuye"],
  sub_industry: "30202010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Agricultura",
  description: "Plantaciones, siembra, alimentos agrícolas",
  tags: ["agricultura", "siembra", "cosecha", "agricola"],
  sub_industry: "30202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Finca ganadera",
  description: "",
  tags: ["ganaderia", "hacienda", "finca", "carne", "res", "cerdo", "cochinos", "ovino", "vacuno", "bovino", "porcino"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Matadero",
  description: "",
  tags: ["matadero"],
  sub_industry: "30202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Carnicería, Frigorífico, Charcutería",
  description: "",
  tags: ["carniceria", "carne", "embutidos", "quesos", "charcuteria", "pollo"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce cerveza",
  description: "",
  tags: ["licores", "alcohol", "alcoholica", "bebidas", "alimentos", "productor", "cerveza"],
  sub_industry: "30201010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Destilería",
  description: "",
  tags: ["licores", "alcohol", "alcoholica", "bebidas", "productor", "ron", "ginebra", "whisky", "whiskey", "sangria", "anis"],
  sub_industry: "30201020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce vinos",
  description: "",
  tags: ["licores", "alcohol", "alcoholica", "bebidas", "alimentos", "productor", "vino", "viñedo", "vinatero"],
  sub_industry: "30201020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Restaurante o café",
  description: "",
  tags: ["Restaurante"],
  sub_industry: "25301040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Clínica",
  description: "",
  tags: ["clinica", "hospital", "health", "care", "centro", "medico"],
  sub_industry: "35102020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Consultorio clínico, odontológico",
  description: "",
  tags: ["consultorio", "clinico", "odontologia", "odontologico"],
  sub_industry: "35102015",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Consultorio psicológico",
  description: "",
  tags: ["psicologia", "psicologico", "mental", "psiquiatria", "psiquiatrico"],
  sub_industry: "35102015",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Consultorio psiquiátrico",
  description: "",
  tags: ["psicologia", "psicologico", "mental", "psiquiatria", "psiquiatrico"],
  sub_industry: "35102015",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Clínica psiquiátrica",
  description: "",
  tags: ["psicologia", "psicologico", "mental", "psiquiatria", "psiquiatrico", "clinica"],
  sub_industry: "35102020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro de fisioterapia",
  description: "",
  tags: ["fisioterapia", "fisiatria", "rehabilitacion", "centro", "medico", "traumatologia"],
  sub_industry: "35102015",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuye equipos médicos",
  description: "",
  tags: ["medicos", "equipos", "instrumentos"],
  sub_industry: "35102010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos médicos e/o insumos",
  description: "",
  tags: ["medicos", "equipos", "instrumentos", "fabrica", "estetoscopio"],
  sub_industry: "35101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce insumos médicos",
  description: "",
  tags: ["medicos", "insumos", "productos", "gasas"],
  sub_industry: "35101020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Corretaje de seguros",
  description: "",
  tags: ["corretaje", "broker", "corredora", "seguros", "polizas"],
  sub_industry: "40301010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Aseguradora",
  description: "Dedicada a múltiples tipos de pólizas.\nSalud, vida, vehículos, patrimoniales, accidentes.",
  tags: ["aseguradora", "polizas", "seguros"],
  sub_industry: "40301030",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Aseguradora de salud y vida",
  description: "Dedicada exclusivamente a pólizas de salud y/o vida",
  tags: ["aseguradora", "polizas", "seguros", "vida", "salud"],
  sub_industry: "40301020",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Aseguradora de patrimonios",
  description: "Dedicada exclusivamente a pólizas patrimoniales o accidentes",
  tags: ["aseguradora", "polizas", "seguros", "patrimonio"],
  sub_industry: "40301040",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Reaseguradora",
  description: "",
  tags: ["reaseguradora"],
  sub_industry: "40301050",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Biotecnología",
  description: "Investigación, desarrollo y producción y/o comercialización de productos de ingeniería genética.",
  tags: ["biotecnologia", "tech"],
  sub_industry: "35201010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Agencia mercadeo y publicidad",
  description: "",
  tags: ["mercadeo", "marketing", "growth", "agencia", "publicidad", "btl", "atl", "diseño", "grafico"],
  sub_industry: "50201010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Agencia de relaciones públicas",
  description: "",
  tags: ["pr", "publicas", "relaciones", "agencia", "mercadeo", "marketing"],
  sub_industry: "50201010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Productora audiovisual",
  description: "",
  tags: ["video", "foto", "audiovisual", "camara", "productora", "3d", "animacion"],
  sub_industry: "50202010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Productora de música",
  description: "",
  tags: ["musica", "sonido", "productora", "audiovisual"],
  sub_industry: "50202010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Crea y vende juegos de mesa",
  description: "",
  tags: ["juegos", "mesa"],
  sub_industry: "50202020",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribución de licores",
  description: "",
  tags: ["licores", "distribucion", "bebidas", "alcoholicas"],
  sub_industry: "25501010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Servicio de catering",
  description: "",
  tags: ["catering"],
  sub_industry: "25301040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Producción de eventos",
  description: "",
  tags: ["eventos", "conciertos", "fiestas", "produccion", "animacion"],
  sub_industry: "25302020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Peluquería y estética",
  description: "",
  tags: ["spa", "estetica", "peluqueria", "manos", "uñas", "pelo", "maquillaje", "barberia", "belleza", "salon"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Spa",
  description: "",
  tags: ["spa", "estetica", "peluqueria", "manos", "uñas", "pelo", "maquillaje", "barberia", "belleza", "salon"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Barbería",
  description: "",
  tags: ["spa", "estetica", "peluqueria", "manos", "uñas", "pelo", "maquillaje", "barberia", "belleza", "salon"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Desarrollo de software, aplicaciones a terceros",
  description: "",
  tags: ["software", "tecnologia", "web", "app", "factory", "webapp", "serivicio", "consultoria", "desarrollo", "tech", "UX", "UI", "desarrollo", "ingenieria"],
  sub_industry: "45103010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Firma de arquitectura y/o diseño de interiores",
  description: "Firmas de arquitectura, diseñadores de interiores,",
  tags: ["arquitectura", "diseño", "construccion", "desarrollo", "inmueble", "inmobiliaria"],
  sub_industry: "20202020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Firma de abogados",
  description: "",
  tags: ["firma", "abogados"],
  sub_industry: "20202020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Constructora de viviendas",
  description: "",
  tags: ["construccion", "vivienda", "constructora"],
  sub_industry: "25201030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Constructora de obras no residenciales",
  description: "",
  tags: ["construccion", "constructora", "residencial", "obras"],
  sub_industry: "20103010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Corredor inmobiliario",
  description: "",
  tags: ["corredor", "corretaje", "inmobiliario"] ,
  sub_industry: "60102040",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Agencia talentos",
  description: "",
  tags: ["agencia", "talento", "humano"],
  sub_industry: "20202010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Agencia de reclutamiento",
  description: "",
  tags: ["headhunter", "head", "hunter", "agencia", "talento", "ejecutivos"],
  sub_industry: "20202010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Red social",
  description: "",
  tags: ["red", "social", "tecnologia", "software", "tech"] ,
  sub_industry: "50203010",
  business_model: "advertising",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabricante de equipos e instrumentos electrónicos",
  description: "",
  tags: ["tecnlogia", "electronico", "electronica", "electrico", "equipos", "hardware", "fabrica", "fabricante"],
  sub_industry: "45203010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda agropecuaria",
  description: "Insumos y equipos para producción agropecuaria",
  tags: ["agropecuaria", "campo", "semillas", "tractores", "insecticidas", "pesticidas", "abono", "alimento", "tractores"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce madera y productos madereros",
  description: "",
  tags: ["produccion", "productora", "productos", "madera", "madereros"],
  sub_industry: "15105010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Metalurgia / Siderurgia",
  description: "",
  tags: ["metalurgia", "siderurgia"],
  sub_industry: "15104020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Librería y papelería",
  description: "",
  tags: ["libros", "libreria", "utiles", "papel", "papeleria", "escolares", "biblioteca", "texto"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Hotel",
  description: "",
  tags: ["hotel", "hospedaje", "turismo"],
  sub_industry: "25301020",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Agencia turismo",
  description: "",
  tags: ["agencia", "turismo"],
  sub_industry: "25301020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Aerolínea",
  description: "",
  tags: ["aerolineas"],
  sub_industry: "20302010",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Operadora de telefonía y/o internet",
  description: "",
  tags: ["operadora", "telefonia", "internet", "comunicaciones"],
  sub_industry: "50101020",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce jabones, detergentes y/o productos de limpieza",
  description: "",
  tags: ["jabon", "detergente", "limpieza", "productor"],
  sub_industry: "30301010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Banco",
  description: "",
  tags: ["banco", "banca", "universal", "credito", "finanzas", "microcredito", "tarjeta", "pagos", "punto", "pos"],
  sub_industry: "40101010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Banco de inversión",
  description: "",
  tags: ["inversion", "banco"],
  sub_industry: "40203020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Asesor de inversión",
  description: "",
  tags: ["inversion", "asesor", "manager", "portfolio", "investment", "mercado", "capitales", "market", "finanzas", "financiero", "bonos", "acciones", "portafolio", "cartera", "family", "fondo", "fund", "hedge", "cobertura"],
  sub_industry: "40203010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Casa de bolsa, sociedad de corretaje",
  description: "",
  tags: ["inversion", "asesor", "manager", "portfolio", "investment", "mercado", "capitales", "market", "finanzas", "financiero", "bonos", "acciones", "portafolio", "cartera", "family", "fondo", "fund", "hedge", "cobertura"],
  sub_industry: "40203020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Bolsa de valores",
  description: "",
  tags: ["bolsa", "valores"],
  sub_industry: "40203040",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Cámaras de compensación de valores",
  description: "",
  tags: ["clearing", "settlement", "caja", "compensacion", "valores"],
  sub_industry: "40203010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Entidad de ahorro y préstamo",
  description: "",
  tags: ["ahorro", "prestamo"],
  sub_industry: "40202010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Bureau de crédito",
  description: "",
  tags: ["bureau", "buro"],
  sub_industry: "40203040",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Holding",
  description: "Inversiones en múltiples empresas",
  tags: ["holding"],
  sub_industry: "40201030",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Entidad crediticia no bancaria",
  description: "",
  tags: ["credito", "crediticio", "bancario"],
  sub_industry: "40201040",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Asesor tributario",
  description: "",
  tags: ["impuestos", "asesor", "asesoria", "tributario", "tributos", "impositivo", "tax"],
  sub_industry: "20202020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Puntos de venta",
  description: "",
  tags: ["punto", "venta"],
  sub_industry: "40201040",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Procesador de pagos no bancario",
  description: "",
  tags: ["pago", "pos", "punto", "venta", "procesador", "banco", "finanzas"],
  sub_industry: "40201040",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Televisión por cable",
  description: "",
  tags: ["cable", "cablera", "tv", "television", "canal"],
  sub_industry: "50201030",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Televisión online, streaming",
  description: "",
  tags: ["television", "online", "streaming"],
  sub_industry: "50201020",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Canal de televisión",
  description: "",
  tags: ["canal", "television"],
  sub_industry: "50201020",
  business_model: "advertising",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Cadena de televisión",
  description: "",
  tags: ["cadena", "television"],
  sub_industry: "50201020",
  business_model: "advertising",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Estación o circuito de radio",
  description: "",
  tags: ["estacion", "radio", "circuito"],
  sub_industry: "50201020",
  business_model: "advertising",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Productora de cine",
  description: "",
  tags: ["productora", "produccion", "cine"] ,
  sub_industry: "50202010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Agencia de noticias",
  description: "",
  tags: ["agencia", "noticia"],
  sub_industry: "50201020",
  business_model: "advertising",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Consultoría en tecnologías de la información",
  description: "",
  tags: ["it", "ti", "tecnolgia", "consultoria", "informacion"],
  sub_industry: "45102010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos de comunicaciones",
  description: "Radios, ...",
  tags: ["equipo", "comunicaciones", "fabrica"],
  sub_industry: "45201020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuye equipos de comunicaciones",
  description: "",
  tags: ["equipo", "comunicaciones", "distribucion"],
  sub_industry: "25501010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica hardware de tecnología",
  description: "Equipos Tecnológicos, Almacenamiento Electrónico",
  tags: ["hardware", "servidores", "alamacenamiento", "equipos", "tecnologia", "electronico"],
  sub_industry: "45202030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica televisores, equipos de sonido, cámaras digitales",
  description: "",
  tags: ["fabrica", "fabricacion", "televisores", "sonidos", "camara", "digital"],
  sub_industry: "25201010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de electrodomésticos y/o electrónicos",
  description: "",
  tags: ["electrodomesticos", "hogar", "tienda"],
  sub_industry: "25504020",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuidores de equipos de tecnología",
  description: "",
  tags: ["distribuidor", "tecnologia"],
  sub_industry: "45203030",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos, instrumentos y/o componentes electrónicos",
  description: "",
  tags: ["fabrica", "fabricacion", "instrumentos", "electronicos", "componentes"] ,
  sub_industry: "45203010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica semiconductores",
  description: "",
  tags: ["semiconductores"],
  sub_industry: "45203015",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Suministro de agua",
  description: "",
  tags: ["agua", "utility", "utilities"],
  sub_industry: "55104010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Suministro de gas",
  description: "",
  tags: ["gas", "servicio", "utility", "utilities"],
  sub_industry: "55102010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Suministro de electricidad",
  description: "",
  tags: ["electricidad", "servicio", "utility", "utilities"],
  sub_industry: "55101010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Productora independiente de energía",
  description: "Productores de energía con fuentes alternativas, como la solar, hidroeléctrica y eólica.\nMercadeo de gas y energía, especialistas de comercio y comerciantes de energía integrada.",
  tags: ["productora", "produccion", "energia"],
  sub_industry: "55105010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce y/o vende energía renovable",
  description: "",
  tags: ["energia", "renovable", "eolica", "electricidad", "agua", "hidroelectrica", "hidro", "planta", "productor"],
  sub_industry: "55105020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce químicos industriales y básicos",
  description: "",
  tags: ["productora", "produccion", "quimicos"],
  sub_industry: "15101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce plásticos",
  description: "",
  tags: ["productora", "produccion", "plasticos"],
  sub_industry: "15101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce pinturas, pigmentos, barnices",
  description: "",
  tags: ["productora", "produccion", "pintura"],
  sub_industry: "15101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce fibras sintéticas",
  description: "",
  tags: ["productora", "produccion", "fibras", "sinteticas"],
  sub_industry: "15101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce explosivos",
  description: "",
  tags: ["productora", "produccion", "explosivos"],
  sub_industry: "15101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce petroquímicos",
  description: "",
  tags: ["productora", "produccion", "petroquimicos"],
  sub_industry: "15101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica fertilizantes y químicos para la agricultura",
  description: "",
  tags: ["fabrica", "manufactura", "fertilizante", "quimicos", "agricultura"],
  sub_industry: "15101030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce gases industriales",
  description: "",
  tags: ["productora", "produccion", "gases", "industriales"],
  sub_industry: "15101040",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica recipientes, envases, contenedores",
  description: "De vidrio, plástico, metal, cartón o papel.\nIncluye fabricación de tapas o tapones.",
  tags: ["fabrica", "manufactura", "recipientes", "envases", "contenedores"],
  sub_industry: "15103010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Produce papel y/o productos de papel",
  description: "Incluye envases y embalajes de papel y/o cartón",
  tags: ["productora", "produccion", "papel"] ,
  sub_industry: "15105020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Minería",
  description: "",
  tags: ["mineria"],
  sub_industry: "15104020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos aeronáuticos",
  description: "",
  tags: ["fabrica", "manufactura", "equipos", "aeronauticos"],
  sub_industry: "20101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos de defensa",
  description: "Armamento, o relacionados",
  tags: ["fabrica", "manufactura", "equipos", "defensa"],
  sub_industry: "20101010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica productos de construcción especializados",
  description: "Ventilación, plomería, pisos.\nProductos nos estructurales",
  tags: ["fabrica", "manufactura", "productos", "construccion", "especializado"],
  sub_industry: "20102010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos y componentes eléctricos",
  description: "Cables, y cualquier componente eléctrico no pesado",
  tags: ["fabrica", "manufactura", "equipos", "componentes", "electricos"],
  sub_industry: "20104010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica equipos y/o material eléctrico pesado",
  description: "Generadores de energía eléctrica (turbinas, plantas), y",
  tags: ["fabrica", "equipos", "material", "electrico", "manufactura"],
  sub_industry: "20104020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Conglomerado industrial",
  description: "",
  tags: ["conglomerado", "industrial"],
  sub_industry: "20105010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica maquinaria pesada para la construcción",
  description: "",
  tags: ["fabrica", "manufactura", "maquinaria", "pesada", "construccion"],
  sub_industry: "20106010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica maquinaria para argicultura y ganadería",
  description: "Tractores, maquinaria de siembra y fertilización, sistemas de riego.",
  tags: ["fabrica", "manufactura", "maquinaria", "agricultura", "ganaderia"],
  sub_industry: "20106015",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica de maquinaria industrial",
  description: "Prensas, herramientas para maquinaria, compresores, equipo para el control de la contaminación, ascensores, escaleras mecánicas, aislantes, bombas, rodamientos y otra maquinaria metálica.",
  tags: ["fabrica", "manufactura", "maquinaria", "industrial"],
  sub_industry: "20106020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuye y comercializa equipos industriales",
  description: "",
  tags: ["distribuidor", "distribucion", "comercio", "comercializacion", "equipos", "industriales"],
  sub_industry: "20107010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica telas, hilos, o productos textiles (no ropa)",
  description: "",
  tags: ["fabrica", "manufactura", "telas", "hilos", "textil"],
  sub_industry: "25203030",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Casino",
  description: "",
  tags: ["casino"],
  sub_industry: "25301010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro hípico",
  description: "",
  tags: ["centro", "hipico"],
  sub_industry: "25301010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro de apuestas",
  description: "",
  tags: ["centro", "apuestas"],
  sub_industry: "25301010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro de juegos de azar",
  description: "",
  tags: ["centro", "juegos", "azar"],
  sub_industry: "25301010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Comercializa videojuegos o aplicaciones freemium",
  description: "Desarrollan videojuegos o aplicaciones (incluyendo realidad virtual o aumentada) cuyos usuarios pueden acceder de manera gratuita a ciertas funcionalidades y deben pagar por acceso a otras mas avanzadas.",
  tags: ["desarrollo", "realidad", "virtual", "ar", "vr", "aumentada", "videojuegos", "appstore", "software", "app", "aplicación", "freemium"],
  sub_industry: "50202020",
  business_model: "freemium",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Comercializa videojuegos o aplicaciones por licencia",
  description: "Desarrollan videojuegos o aplicaciones (incluyendo realidad virtual o aumentada) y venden una licencia de uso por un one-time fee.",
  tags: ["desarrollo", "realidad", "virtual", "ar", "vr", "aumentada", "videojuegos", "appstore", "software", "app", "aplicación", "licencia"],
  sub_industry: "50202020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Comercializa videojuegos o aplicaciones por suscripción",
  description: "Desarrollan videojuegos o aplicaciones (incluyendo realidad virtual o aumentada) y venden una suscripción que otorga acceso al producto a cambio de pagos recurrentes.",
  tags: ["desarrollo", "realidad", "virtual", "ar", "vr", "aumentada", "videojuegos", "appstore", "software", "app", "aplicación", "suscripción"],
  sub_industry: "50202020",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Resort",
  description: "",
  tags: ["resort", "turismo"],
  sub_industry: "25301020",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Cruceros",
  description: "",
  tags: ["crucero", "turismo"],
  sub_industry: "25301020",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Campamentos y planes vacacionales",
  description: "",
  tags: ["campamento", "plan", "vacacional"],
  sub_industry: "25301030",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Posada",
  description: "",
  tags: ["posada", "turismo"],
  sub_industry: "25301020",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Hotel boutique",
  description: "",
  tags: ["hotel", "boutique", "turismo"],
  sub_industry: "25301020",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Parque o centro de recreación",
  description: "",
  tags: ["parque", "centro", "recreacion"],
  sub_industry: "25301030",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica indumentaria o equipamiento deportivo",
  description: "",
  tags: ["fabrica", "manufactura", "equipos", "deportivos"],
  sub_industry: "25202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica juguetes",
  description: "",
  tags: ["fabrica", "manufactura", "juguete"],
  sub_industry: "25202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica bicicletas",
  description: "",
  tags: ["fabrica", "manufactura", "bicicleta"],
  sub_industry: "25202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica productos de campamento y actividades al aire libre",
  description: "",
  tags: ["fabrica", "producto", "manufactura", "campamento", "actividad", "aire", "libre", "exterior"],
  sub_industry: "25202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Colegio",
  description: "",
  tags: ["colegio", "educacion", "recreacion"],
  sub_industry: "25302010",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Universidad",
  description: "",
  tags: ["universidad", "educacion"],
  sub_industry: "25302010",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Instituto, academia o escuela especializada",
  description: "",
  tags: ["colegio", "educacion", "recreacion", "universidad"],
  sub_industry: "25302010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro deportivo con fee de acceso",
  description: "Centro deportivo abierto al público que vende una entrada para acceder",
  tags: ["gimnasio", "deporte", "centro", "deportivo", "fit", "fitness", "fitbox", "box", "gym"],
  sub_industry: "25301030",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro deportivo o gimnasio con suscripción",
  description: "Centro deportivo abierto al público que vende una membresía para uso de las instalaciones",
  tags: ["gimnasio", "deporte", "centro", "deportivo", "fit", "fitness", "fitbox", "box", "gym"],
  sub_industry: "25301030",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Preescolar",
  description: "Suscripción por año escolar",
  tags: ["colegio", "preescolar", "educacion", "recreacion"],
  sub_industry: "25302010",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Guardería o servicio de cuidado de niños",
  description: "Centro de cuidado de bebes y niños que cobra por uso",
  tags: ["guardería", "colegio", "preescolar", "educacion", "recreacion", "baby", "bebe", "niños", "cumpleaños", "piñata", "campamento"],
  sub_industry: "25302010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Planificador de eventos",
  description: "",
  tags: ["wedding", "planner", "eventos", "planificador"],
  sub_industry: "25302020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fotografía y/o video de eventos",
  description: "",
  tags: ["wedding", "planner", "eventos", "planificador", "video", "foto"],
  sub_industry: "25302020",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Club social",
  description: "",
  tags: ["club", "social"],
  sub_industry: "25301030",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Equipo/club deportivo",
  description: "",
  tags: ["equipo", "club", "deportivo"],
  sub_industry: "50202010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabricante de neumáticos y/o correas",
  description: "",
  tags: ["partes", "auto", "repuestos", "vehiculos", "carros", "camiones", "autobuses", "tractores", "cauchos", "neumaticos", "correas"],
  sub_industry: "25101020",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda por departamento",
  description: "",
  tags: ["tienda", "departamento"],
  sub_industry: "25503010",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de ropa y accesorios",
  description: "",
  tags: ["ropa", "moda", "joyas", "lujo", "joyeria", "prendas", "pantalones", "camisas", "interior", "lingerie", "franelas", "medias", "chaquetas", "vestir", "vestimenta", "tienda"],
  sub_industry: "25504010",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Joyería",
  description: "",
  tags: ["joyeria"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de electrónicos, equipos de computación, tecnología",
  description: "",
  tags: ["electronicos", "tienda", "computacion", "tecnologia"],
  sub_industry: "25504020",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de muebles y/o artículos del hogar",
  description: "",
  tags: ["muebles", "articulos", "hogar"],
  sub_industry: "25504060",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de artículos deportivos",
  description: "",
  tags: ["deportes", "tienda", "articulos", "deportivos"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Óptica",
  description: "",
  tags: ["optica", "vista"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Juguetería",
  description: "",
  tags: ["jugueteria", "juguete", "tienda"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Concesionario de vehículos",
  description: "",
  tags: ["concesionario", "vehiculos", "carros", "motos"],
  sub_industry: "25504050",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de cosméticos y/o perfumería",
  description: "",
  tags: ["perfumeria", "cosmeticos"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Funeraria",
  description: "",
  tags: ["funeraria", "cementerio", "muerte", "vida"],
  sub_industry: "35102015",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Cementerio",
  description: "",
  tags: ["funeraria", "cementerio", "muerte", "vida"],
  sub_industry: "35102015",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Kiosco",
  description: "",
  tags: ["kiosco", "tienda"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Zapateria",
  description: "",
  tags: ["zapatos", "carteras", "correas", "cinturon", "bolsos"],
  sub_industry: "25504010",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda alfombras",
  description: "",
  tags: ["alfombras", "tienda"],
  sub_industry: "25504060",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Jardinería y/o vivero",
  description: "",
  tags: ["jardineria", "vivero", "hogar"],
  sub_industry: "25504030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Floristería",
  description: "",
  tags: ["floristeria", "flores"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Taller mecánico",
  description: "",
  tags: ["mecanico", "carro", "taller", "automovil", "repuestos", "autopartes", "reparacion"],
  sub_industry: "25504050",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Estación de gasolina",
  description: "",
  tags: ["gasolina", "estacion", "bomba"],
  sub_industry: "25504050",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de instrumentos musicales",
  description: "",
  tags: ["instrumentos", "musicales", "tienda"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica instrumentos musicales",
  description: "",
  tags: ["instrumentos", "musicales", "fabrica"],
  sub_industry: "25202010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Licorería",
  description: "",
  tags: ["licoreria", "tienda"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de celulares, accesorios",
  description: "",
  tags: ["celulares", "accesorios", "tienda"],
  sub_industry: "25504020",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda erótica",
  description: "",
  tags: ["sex", "shop"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Salas de cine y/o teatro",
  description: "",
  tags: ["cine", "teatro", "salas"],
  sub_industry: "50202010",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuidora de películas de cine",
  description: "",
  tags: ["peliculas", "cine", "distribucion", "distribuidora"],
  sub_industry: "50202010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro comercial",
  description: "",
  tags: ["centro", "comercial"],
  sub_industry: "60102020",
  business_model: "leasing",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Piñatería",
  description: "",
  tags: ["piñateria", "jugueteria"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Bar y/o discoteca",
  description: "",
  tags: ["bar", "discoteca", "fiesta"],
  sub_industry: "25301040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de bicicletas",
  description: "",
  tags: ["bicicletas"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Centro de imageneología",
  description: "",
  tags: ["resonancia", "magnetica", "imagen", "imageneología", "rayosx", "x", "radiografia"],
  sub_industry: "35102015",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Laboratorio médico",
  description: "",
  tags: ["laboratorio", "medico"],
  sub_industry: "35102015",
  business_model: "product-as-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Autolavado",
  description: "",
  tags: ["autolavado"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fabrica cigarrillos y/o tabaco",
  description: "",
  tags: ["tabaco", "tabacalera", "cigarro", "cigarrillo"],
  sub_industry: "30203010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Distribuidor de tabaco y/o cigarrillos",
  description: "",
  tags: ["tabaco", "tabacalera", "cigarro", "cigarrillo", "distribuidor"],
  sub_industry: "25501010",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Desarrollo de tecnología o servicios de TI para el sector médico",
  description: "",
  tags: ["software", "medico", "clinica", "tecnología"],
  sub_industry: "35103010",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Panadería",
  description: "",
  tags: ["panaderia"],
  sub_industry: "30101030",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Tienda de carpintería",
  description: "",
  tags: ["carpinteria", "madera", "taller"],
  sub_industry: "25504040",
  business_model: "retailer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Proyectos de carpitería",
  description: "",
  tags: ["carpinteria", "madera", "taller"],
  sub_industry: "15105010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Seguridad personal y residencial",
  description: "",
  tags: ["seguridad", "personal", "residencial"],
  sub_industry: "25302020",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Planta de tratamiento de aguas",
  description: "",
  tags: ["tratamiento", "aguas", "planta"],
  sub_industry: "20201050",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Relleno sanitario, vertedero",
  description: "",
  tags: ["sanitario", "relleno", "vertedero"],
  sub_industry: "20201050",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Recolección de desechos",
  description: "",
  tags: ["desechos", "recoleccion", "basura"],
  sub_industry: "20201050",
  business_model: "fee-for-service",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Imprenta",
  description: "",
  tags: ["imprenta", "prensa", "vallas", "plotter"],
  sub_industry: "20201010",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Fábrica de cristalería, cubertería y/o artículos de cocina",
  description: "No electrodomésticos",
  tags: ["cristaleria", "cuberteria", "cocina", "articulos", "hogar", "fabrica"],
  sub_industry: "25201050",
  business_model: "manufacturer",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Mayorista de alimentos",
  description: "",
  tags: ["mayorista", "alimentos"],
  sub_industry: "30101020",
  business_model: "distribution",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}, {
  display_name: "Servicios de asistencia por suscripción",
  description: "",
  tags: ["servicio", "asistencia", "suscripcion"],
  sub_industry: "25302020",
  business_model: "subscription",
  extra_distribution_model: undefined,
  products_imported: undefined,
  commercial_places: -1,
  additional_info: undefined,
}];

export function getBusinessActivities(query: string): IBusinessActivity[] {

  let fuseOptions: Fuse.IFuseOptions<IBusinessActivity> = {
    includeScore: true,
    includeMatches: true,
    minMatchCharLength: 4,
    threshold: 0.2,
    keys: ['display_name', 'tags'],
  };
  let fuse = new Fuse(businessActivities, fuseOptions);

  return fuse.search(query).map((result) => {
    let businessActivity = result.item;
    businessActivity.business_model = getBusinessModels().find(bm => {
      return bm.name === businessActivity.business_model;
    }) || businessActivity.business_model;
    businessActivity.sub_industry = getIndustryClassifications().find(subindustry => {
      return subindustry.code === businessActivity.sub_industry;
    }) || businessActivity.sub_industry;
    return result.item;
  });
}