import { AxiosError, AxiosResponse } from 'axios';
import { GoogleLoginForm } from '../interfaces/google-login-form';
import { LoginForm } from '../interfaces/login-form';
import { SignupForm } from '../interfaces/signup-form';
import { User } from '../interfaces/user';
import { apiClient } from './base-api-client';

/**
 * Attempt to login with the provided credentials
 * 
 * @param data The login credentials
 * @returns Object containing the authentication token and user details
 */
const login = async (data: LoginForm): Promise<User | AxiosError> => {
  return apiClient.post<LoginForm, AxiosResponse<User>>('/auth/login', data)
    .then((response: AxiosResponse<User>) => response.data)
    .catch((error: any) => {
      return error;
    });
};

/**
 * Attempt to login with the provided Google credentials
 * 
 * @param data The Google login code and redirect URL
 * @returns Object containing the authentication token and user details
 */
const googleLogin = async (data: GoogleLoginForm): Promise<User> => {
  return apiClient.get<GoogleLoginForm, AxiosResponse<User>>(`/oauth/google/continue?code=${data.code}&redirect_uri=${data.redirect_uri}`)
    .then((response: AxiosResponse<User>) => response.data)
    .catch((error: any) => {
      return error;
    });
};

/**
 * Sign up a new user
 * 
 * @param data The user's details
 * @returns User object
 */
const signup = async (data: SignupForm): Promise<User> => {
  return apiClient.post<SignupForm, AxiosResponse<any>>('/auth/signup', data)
    .then((response: AxiosResponse<User>) => response.data)
    .catch((error: any) => {
      return error;
    });
};


export { login, googleLogin, signup };