
// TODO: Move to a separate file
interface VCenteredContainerProps {
  children: React.ReactNode;
}

export default function VCenteredContainer({ children }: VCenteredContainerProps): JSX.Element {
  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      {children}
    </div>
  )
}