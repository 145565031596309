import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/loader/loader";
import VCenteredContainer from "../../components/v-centered-container/v-centered-container";
import { AppContext } from "../../contexts/app.context";
import { googleLogin } from "../../services/auth";
import { tokenService } from "../../services/token";

export function AuthGoogle(): JSX.Element {
  const [searchParams, /* setSearchParams */] = useSearchParams();
  const navigate = useNavigate();
  const { setUser } = React.useContext(AppContext);

  useEffect(() => {
    
    const login = async () => {
      const code = searchParams.get('code');
      if (code) {
        const body = {
          code,
          redirect_uri: 'http://localhost:3000/auth/google',
        }
        const user = await googleLogin(body);
        setUser(user);
        if (user.token) {
          tokenService.updateLocalAccesToken(user.token);
        }
        navigate('/');
      } else {
        navigate('/');
      }
    }

    login();
  }, [navigate, searchParams, setUser]);

  return (
    <VCenteredContainer>
      <Loader />
    </VCenteredContainer>
  )
}