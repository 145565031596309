import { Fragment } from "react";
import TextFieldProps from "../../interfaces/text-field-props";

export default function TextField(props: TextFieldProps) {
  return (
    <Fragment>
      <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <input
          id={props.id || props.name}
          name={props.name}
          type="text"
          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
          aria-describedby={`${props.name}-description`}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
      {
        props.hint !== undefined &&
        <p className="mt-2 text-sm text-gray-500" id={`${props.name}-description`}>
          {props.hint}
        </p>
      }
    </Fragment>
  )
}