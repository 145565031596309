import IIndustryClassification from "../interfaces/industry-classification";

const industryClassifications: IIndustryClassification[] = [{
  sector: "Energía",
  industry_group: "Energía",
  industry: "Equipos y Servicios de Energía",
  sub_industry: "Perforación de Pozos Petrolíferos y Gasíferos",
  code: "10101010",
  description: "Contratistas de perforación o propietarios de torres de perforación que contratan sus servicios para perforar pozos."
}, {
  sector: "Energía",
  industry_group: "Energía",
  industry: "Equipos y Servicios de Energía",
  sub_industry: "Equipos y Servicios Relacionados con el Petróleo y Gas",
  code: "10101020",
  description: "Fabricantes de equipos, como torres y equipos de perforación, proveedores de suministros y servicios para empresas dedicadas a la perforación, evaluación y explotación de pozos petrolíferos y gasíferos."
}, {
  sector: "Energía",
  industry_group: "Energía",
  industry: "Petroleo, Gas y Combustibles",
  sub_industry: "Petróleo y Gas Integrados",
  code: "10102010",
  description: "Empresas petroleras integradas dedicadas a la exploración y producción de petróleo y gas, asi como alguna otra actividad importante relacionada con el refinado, comercialización y transporte, o productos químicos."
}, {
  sector: "Energía",
  industry_group: "Energía",
  industry: "Petroleo, Gas y Combustibles",
  sub_industry: "Producción y Exploración de Petróleo y Gas",
  code: "10102020",
  description: "Empresas dedicadas a la exploración y producción de petróleo y gas no incluidas en otra clasificación."
}, {
  sector: "Energía",
  industry_group: "Energía",
  industry: "Petroleo, Gas y Combustibles",
  sub_industry: "Refinado y Marketing de Petróleo y Gas",
  code: "10102030",
  description: "Empresas dedicadas al refinado y mercadeo de petróleo y gas, asi como de productos refinados no clasificados en las ramas industriales de petróleo y gas integrados o de los Productores de electricidad y operadores de energía independientes."
}, {
  sector: "Energía",
  industry_group: "Energía",
  industry: "Petroleo, Gas y Combustibles",
  sub_industry: "Almacenado y Transporte de Petróleo y Gas",
  code: "10102040",
  description: "Empresas dedicadas al almacenamiento y/o transporte de petróleo, gas o productos refinados. Se incluyen empresas distribuidoras de gas natural diversificadas que deben hacer frente a mercados competitivos, a oleoductos y conductos para productos refinados, conductos para transporte de carbón y empresas de distribución de gas y petróleo."
}, {
  sector: "Energía",
  industry_group: "Energía",
  industry: "Petroleo, Gas y Combustibles",
  sub_industry: "Carbón y Carburantes Combustibles",
  code: "10102050",
  description: "Empresas dedicadas principalmente a la producción y extracción de carbón, de productos relacionados y de otros combustibles de consumo relacionados con la generación de energía. Quedan excluidas las empresas que producen  principalmente gases clasificados en la Sub-Industria de Gases Industriales y empresas mineras dedicadas principalmente a la producción metalurgica de carbón utilizado para la producción de acero."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Productos Químicos",
  sub_industry: "Productos Químicos Generales",
  code: "15101010",
  description: "Empresas dedicadas principalmente a la elaboración de productos químicos industriales y básicos. Se incluyen, entre otros, plásticos, fibras sintéticas, películas, pinturas y pigmentos, explosivos y productos petroquímicos. Se excluyen las empresas químicas incluidas en las ramas Productos Químicos Diversos, Fertilizantes y Agroquímicos, Gases Industriales o Productos Químicos Especializados."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Productos Químicos",
  sub_industry: "Productos Químicos Diversos",
  code: "15101020",
  description: "Empresas dedicadas a la elaboración de una amplia gama de productos químicos, no incluidos en las ramas de Gases Industriales, Productos Químicos Generales, Productos Químicos Especializados o Fertilizantes y Agroquímicos."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Productos Químicos",
  sub_industry: "Fertilizantes y Agroquímicos",
  code: "15101030",
  description: "Empresas dedicadas a la elaboración de fertilizantes, pesticidas, potasa u otros productos químicos relacionados con la agricultura no incluidas en otras clasificaciones."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Productos Químicos",
  sub_industry: "Gases Industriales",
  code: "15101040",
  description: "Empresas dedicadas a la producción de gases industriales."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Productos Químicos",
  sub_industry: "Productos Químicos Especializados",
  code: "15101050",
  description: "Empresas dedicadas principalmente a la elaboración de productos químicos de alto valor añadido, utilizados en la fabricación de una gran variedad de productos como, entre otros, químicos refinados, aditivos, polímeros avanzados, adhesivos, selladores, pinturas especiales, pigmentos y revestimientos especializados."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Materiales de Construcción",
  sub_industry: "Materiales de Construcción",
  code: "15102010",
  description: "Empresas dedicadas a la producción de materiales de construcción como arena, arcilla, yeso, cal, conglomerado, cemento, hormigón y ladrillos, entre otros. Otros materiales de construcción acabados o semiacabados están clasificados en la rama Productos de Construcción."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Contenedores y Embalaje",
  sub_industry: "Contenedores de Metal y Cristal",
  code: "15103010",
  description: "Empresas dedicadas a la producción de contenedores de metal, cristal o plástico. Se incluyen tapas y tapones."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Contenedores y Embalaje",
  sub_industry: "Embalaje de Papel",
  code: "15103020",
  description: "Empresas dedicadas a la producción de contenedores y embalajes de papel y cartón."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Metales y Minería",
  sub_industry: "Aluminio",
  code: "15104010",
  description: "Empresas dedicadas a la producción de aluminio y productos relacionados. Se incluyen las empresas dedicadas a la extracción o procesamiento de bauxita y aquellas dedicadas al reciclaje de aluminio para producir productos acabados o semiacabados. Se excluyen las empresas dedicadas principalmente a la producción de materiales de construcción de aluminio que están clasificadas en la rama Productos de Construcción."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Metales y Minería",
  sub_industry: "Minería y Metales Diversos",
  code: "15104020",
  description: "Empresas dedicadas a la produccion o extraccion de una amplia variedad de metales y minerales no incluidos en otras clasificaciones anteriormente. Incluye, entre otros, la mineria de metales no ferrosos (excepto Bauxita), la mineria de sal y borato, la mineria de roca fosforica, y operaciones de mineria diversas. Excluye la mineria de hierro, clasificado en la rama de Acero, la mineria de bauxita, clasificada en la rama de Aluminio, y la mineria del carbon, clasificada en la rama del Acero o la rama de Carbon y Carburantes Combustibles."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Metales y Minería",
  sub_industry: "Cobre",
  code: "15104025",
  description: "Empresas dedicadas principalmente a la extracción de cobre."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Metales y Minería",
  sub_industry: "Oro",
  code: "15104030",
  description: "Empresas dedicadas a la producción de oro y productos relacionados. Se incluyen las empresas que extraen o procesan oro y las casas de finanzas de Sudáfrica que invierten principalmente en minas de oro pero no las operan."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Metales y Minería",
  sub_industry: "Minerales y Metales Preciosos",
  code: "15104040",
  description: "Empresas dedicadas a la extracción de minerales y metales preciosos no clasificadas en la rama Oro. Se incluyen las empresas dedicadas principalmente a la extracción de platino."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Metales y Minería",
  sub_industry: "Plata",
  code: "15104045",
  description: "Empresas dedicadas principalmente a la extracción de plata. Se excluyen empresas clasificadas en las ramas Oro o Minerales y Metales Preciosos."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Metales y Minería",
  sub_industry: "Acero",
  code: "15104050",
  description: "Productores de hierro, acero y productos ralacionados, incluyendo mineria de carbon metalurgico (coque) para la produccion de acero."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Productos Madereros y Papeleros",
  sub_industry: "Productos Madereros",
  code: "15105010",
  description: "Empresas dedicadas a la producción de madera y productos relacionados. Se incluye la madera producida para la construcción."
}, {
  sector: "Materiales",
  industry_group: "Materiales",
  industry: "Productos Madereros y Papeleros",
  sub_industry: "Productos de Papel",
  code: "15105020",
  description: "Empresas dedicadas a la producción de papel de todo tipo. Se excluyen las empresas especializadas en embalajes de papel clasificadas en la rama Embalaje de Papel."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Industria Aeronáutica y de Defensa",
  sub_industry: "Industria Aeronáutica y de Defensa",
  code: "20101010",
  description: "Empresas dedicadas a la fabricación de componentes, equipos o productos civiles o militares para la industria aeronáutica y de defensa. Incluye aparatos electrónicos de defensa y equipos espaciales."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Productos de Construcción",
  sub_industry: "Productos de Construcción",
  code: "20102010",
  description: "Empresas dedicadas a la fabricación de componentes de construcción, productos y equipos para la restauración de edificios y casas. Se excluyen la madera y el contrachapado, clasificados en la rama Productos Madereros, y al cemento y otros materiales clasificados en la rama Materiales de Construcción."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Construcción e Ingeniería",
  sub_industry: "Construcción e Ingeniería",
  code: "20103010",
  description: "Empresas que operan fundamentalmente en el sector de la construcción no residencial. Se incluyen las empresas de ingeniería civil y grandes contratistas. Se excluyen las empresas clasificadas en la rama Construcción Residencial."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Equipos Eléctricos",
  sub_industry: "Equipos y Componentes Eléctricos",
  code: "20104010",
  description: "Empresas dedicadas a la fabricación de cables, equipos o componentes eléctricos no clasificados en la rama Equipos Eléctricos Pesados."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Equipos Eléctricos",
  sub_industry: "Equipos Eléctricos Pesados",
  code: "20104020",
  description: "Empresas dedicadas a la fabricación de equipos generadores de energía y otros equipos eléctricos pesados, como turbinas de generación, maquinaria eléctrica pesada diseñada para uso fijo y sistemas eléctricos de gran tamaño. Se excluyen los cables, clasificados en la rama Equipo y componentes eléctricos."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Conglomerados Industriales",
  sub_industry: "Conglomerados Industriales",
  code: "20105010",
  description: "Empresas industriales diversificadas con actividades comerciales en tres o más sectores y cuya fuente principal de ingresos no puede atribuirse específicamente a un sector concreto. Las participaciones que tienen los accionistas de estas compañias son predominantemente de carácter estratégico y mantienen un interés operacional en la dirección de las subsidiarias."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Maquinarias",
  sub_industry: "Maquinaria de Construccion y Camiones Pesados",
  code: "20106010",
  description: "Fabricantes de camiones pesados, maquinaria de ruedas, equipos de construccion y excavacion y fabricantes de repuestos. Incluye los fabricantes de barcos no militares."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Maquinarias",
  sub_industry: "Maquinaria de Agricultura y Cultivo",
  code: "20106015",
  description: "Fabricantes de maquinaria de agricultura, maquinaria de cultivo y repuestos. Incluye maquinaria utilizada para la produccion de sembrios y ganado, tractores, maquinaria de sembrado y fertilizacion, equipos de aplicacion de fertilizantes y químicos y ventiladores y secadoras de granos."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Maquinarias",
  sub_industry: "Maquinaria Industrial",
  code: "20106020",
  description: "Empresas dedicadas a la fabricación de maquinaria y componentes industriales. Se incluyen las prensas, herramientas para maquinaria, compresores, equipo para el control de la contaminación, ascensores, escaleras mecánicas, aislantes, bombas, rodamientos y otra maquinaria metálica."
}, {
  sector: "Industrial",
  industry_group: "Bienes de Equipo",
  industry: "Comercio y Distribución",
  sub_industry: "Comercio y Distribución",
  code: "20107010",
  description: "Empresas dedicadas al comercio y distribución de productos y equipos industriales."
}, {
  sector: "Industrial",
  industry_group: "Servicios Comerciales y Profesionales",
  industry: "Suministros y Servicios Comerciales",
  sub_industry: "Impresión Comercial",
  code: "20201010",
  description: "Empresas dedicadas a servicios de impresión comercial. Se incluyen las empresas que ofrecen servicios de impresión a medios de comunicación."
}, {
  sector: "Industrial",
  industry_group: "Servicios Comerciales y Profesionales",
  industry: "Suministros y Servicios Comerciales",
  sub_industry: "Servicios e Instalaciones Medioambientales",
  code: "20201050",
  description: "Empresas que proporcionan servicios medioambientales. Se incluyen los servicios de tratamiento de residuos y de control de la contaminación. Se excluyen los sistemas de tratamiento de agua a gran escala clasificados en la rama Suministro de Agua."
}, {
  sector: "Industrial",
  industry_group: "Servicios Comerciales y Profesionales",
  industry: "Suministros y Servicios Comerciales",
  sub_industry: "Suministros y Servicios de Oficina",
  code: "20201060",
  description: "Empresas que proporcionan servicios o fabrican equipo de oficina, no incluidas en otra clasificación."
}, {
  sector: "Industrial",
  industry_group: "Servicios Comerciales y Profesionales",
  industry: "Suministros y Servicios Comerciales",
  sub_industry: "Servicios de Apoyo Diversificados",
  code: "20201070",
  description: "Empresas que proporcionan principalmente servicios de apoyo orientados a la mano de obra, para empresas y gobiernos. Incluye servicios de limpieza comercial, servicios de comidas y catering, servicios de reparación de equipos, servicios de mantenimiento industrial, subastadores industriales, almacenamiento y depósito, servicios de transacción, servicios de alquiler de uniformes y otros servicios de apoyo a las empresas."
}, {
  sector: "Industrial",
  industry_group: "Servicios Comerciales y Profesionales",
  industry: "Suministros y Servicios Comerciales",
  sub_industry: "Servicios de Seguridad y Alarmas",
  code: "20201080",
  description: "Empresas que proporcionan servicios de seguridad y protección a empresas y gobiernos. Incluye empresas que proporcionan servicios como instalaciones correccionales, servicios de seguridad y alarmas, transporte blindado y guardias. Se excluyen empresas que proporcionan programas informáticos de seguridad, clasificadas en la rama de Software de Sistemas y los servicios de seguridad doméstica, clasificados en la rama de Servicios de Consumo Especializados. También se excluyen empresas fabricantes de equipo para sistemas de seguridad, clasificadas en la rama de Equipo e Instrumentos Electrónicos."
}, {
  sector: "Industrial",
  industry_group: "Servicios Comerciales y Profesionales",
  industry: "Servicios Profesionales",
  sub_industry: "Servicios de Recursos Humanos y Empleo",
  code: "20202010",
  description: "Empresas que proporcionan servicios de apoyo de mercado relacionados con la administración de capital humano. Se Incluyen agencias de empleo, de formación de empleados, de servicios de apoyo de nómina y prestaciones, servicios de apoyo en la jubilación y agencias de trabajadores temporales."
}, {
  sector: "Industrial",
  industry_group: "Servicios Comerciales y Profesionales",
  industry: "Servicios Profesionales",
  sub_industry: "Servicios de Investigación y Consultoría",
  code: "20202020",
  description: "Empresas que proporcionan principalmente servicios de investigación y consultoría a empresas y gobiernos,  no incluidas en ninguna otra clasificación. Se incluyen las empresas que prestan servicios de consultoría en administración, diseño arquitectónico, información comercial o investigación científica, marketing y servicios de prueba y certificación. Se excluyen empresas que proporcionen servicios de consultoría en tecnología de la infomación, clasificadas en la rama de Consultoría de Tecnología de la Información y Otros Servicios."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Servicios Logísticos y de Transporte Aéreo de Mercancías",
  sub_industry: "Servicios Logísticos y de Transporte Aéreo de Mercancías",
  code: "20301010",
  description: "Empresas que proporcionan servicios logísticos y de transporte aéreo de mercancías. Se incluyen servicios de envío de paquetes y correo, así como agentes de aduanas. Se excluyen las empresas clasificadas en las ramas Líneas Aéreas, Transporte Marítimo o Transporte por Carretera."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Líneas Aéreas",
  sub_industry: "Líneas Aéreas",
  code: "20302010",
  description: "Empresas dedicadas principalmente al transporte aéreo de pasajeros."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Transporte Marítimo",
  sub_industry: "Transporte Marítimo",
  code: "20303010",
  description: "Empresas dedicadas al transporte marítimo de mercancías o pasajeros. Se excluyen los cruceros clasificados en la rama de Hoteles, Centros de Vacaciones y Cruceros."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Carreteras y Ferrocarriles",
  sub_industry: "Vías Férreas",
  code: "20304010",
  description: "Empresas que proporcionan servicios de transporte ferroviario de mercancías y pasajeros."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Carreteras y Ferrocarriles",
  sub_industry: "Transporte por Carretera",
  code: "20304020",
  description: "Empresas que proporcionan servicios de transporte terrestre de mercancías y pasajeros. Se incluyen los servicios de taxi y alquiler de vehículos."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Infraestructura de Transportes",
  sub_industry: "Servicios de Aeropuertos",
  code: "20305010",
  description: "Operadores de aeropuertos y empresas que proporcionan servicios relacionados."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Infraestructura de Transportes",
  sub_industry: "Carreteras y Vías Férreas",
  code: "20305020",
  description: "Propietarios y operadores de carreteras, túneles y vías férreas."
}, {
  sector: "Industrial",
  industry_group: "Transportes",
  industry: "Infraestructura de Transportes",
  sub_industry: "Servicios y Puertos Marítimos",
  code: "20305030",
  description: "Propietarios y operadores de puertos marítimos y servicios relacionados."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Automóviles y Componentes",
  industry: "Componentes de Automóviles",
  sub_industry: "Equipo y Componentes de Automóviles",
  code: "25101010",
  description: "Empresas dedicadas a la fabricación de piezas y accesorios de automóviles y motocicletas. Se excluyen las empresas clasificadas en la rama Neumáticos y Caucho."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Automóviles y Componentes",
  industry: "Componentes de Automóviles",
  sub_industry: "Neumáticos y Caucho",
  code: "25101020",
  description: "Empresas dedicadas a la fabricación de neumáticos y caucho."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Automóviles y Componentes",
  industry: "Automóviles",
  sub_industry: "Fabricantes de Automóviles",
  code: "25102010",
  description: "Empresas dedicadas principalmente a la fabricación de automóviles de pasajeros y camiones ligeros. Se excluyen los fabricantes de motocicletas y vehículos de tres ruedas, clasificados en la rama Fabricantes de Motocicletas y los fabricantes de camiones pesados, clasificados en la rama de Maquinaria de Construcción y Vehículos Pesados."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Automóviles y Componentes",
  industry: "Automóviles",
  sub_industry: "Fabricantes de Motocicletas",
  code: "25102020",
  description: "Empresas dedicadas a la fabricación de motocicletas, patinetas o vehículos de tres ruedas. Se excluyen los fabricantes de bicicletas, clasificados en la rama Productos Recreativos."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Bienes de Uso  Doméstico Duradero",
  sub_industry: "Aparatos Eléctricos",
  code: "25201010",
  description: "Fabricantes de aparatos electrónicos de consumo doméstico, incluyendo televisores, equipos de alta fidelidad, consolas de juego, camaras digitales y productos relacionados. Se excluyen los fabricantes de computadoras personales, clasificados en la rama de Hardware, Almacenamiento y Periféricos, así como los de electrodomésticos, clasificados en la rama de Electrodomésticos."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Bienes de Uso  Doméstico Duradero",
  sub_industry: "Mobiliario para el Hogar",
  code: "25201020",
  description: "Empresas dedicadas a la fabricación de mobiliario para el hogar. Se incluyen los fabricantes de tapicería, alfombras o revestimiento de paredes."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Bienes de Uso  Doméstico Duradero",
  sub_industry: "Construcción de Viviendas",
  code: "25201030",
  description: "Empresas dedicadas a la construcción residencial. Se incluyen los constructores de casas prefabricadas y semifijas."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Bienes de Uso  Doméstico Duradero",
  sub_industry: "Electrodomésticos",
  code: "25201040",
  description: "Empresas dedicadas a la fabricación de electrodomésticos y productos relacionados. Se incluyen los fabricantes de aparatos eléctricos, herramientas y utensilios de jardinería. Se excluyen los fabricantes de televisores y otros productos de audio y vídeo clasificados en la rama de Equipos Tecnológicos, Almacenamiento Electrónico y Periféricos.\n"
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Bienes de Uso  Doméstico Duradero",
  sub_industry: "Articulos Domésticos de Cocina y Cristalería",
  code: "25201050",
  description: "Empresas dedicadas a la fabricación de artículos domésticos duraderos como cuberterías, utensilios de cocina, cristalerías u otros artículos de cristal o plata, y otros productos domésticos especializados, no incluidas en otra clasificación."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Productos Recreativos",
  sub_industry: "Productos Recreativos",
  code: "25202010",
  description: "Empresas dedicadas a la fabricación de equipos y productos de recreación. Se incluyen los fabricantes de equipos deportivos, bicicletas y juguetes."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Articulos Textiles, de Confección y Bienes de Lujo",
  sub_industry: "Articulos de Confección, Accesorios y Bienes de Lujo",
  code: "25203010",
  description: "Empresas dedicadas a la confección y elaboración de accesorios y bienes de lujo. Se incluyen los fabricantes de bolsos, carteras, equipaje, joyería y relojería de diseño. Se excluyen los fabricantes de calzado clasificados en la rama Calzado."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Articulos Textiles, de Confección y Bienes de Lujo",
  sub_industry: "Calzado",
  code: "25203020",
  description: "Empresas dedicadas a la fabricación de calzado. Se incluyen los fabricantes de calzado deportivo y de cuero."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Bienes de Consumo Duradero y Confección",
  industry: "Articulos Textiles, de Confección y Bienes de Lujo",
  sub_industry: "Textil",
  code: "25203030",
  description: "Empresas dedicadas a la fabricación textil y de artículos relacionados, no incluidas en las ramas Confección, Accesorios y Bienes de Lujo, Calzado o Mobiliario para el Hogar."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Servicios al Consumidor",
  industry: "Hoteles, Restaurantes y Recreacion",
  sub_industry: "Casinos y Juegos de Azar",
  code: "25301010",
  description: "Propietarios y operadores de casinos y salones recreativos y de juego. Se incluyen las empresas de lotería y servicios de apuestas."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Servicios al Consumidor",
  industry: "Hoteles, Restaurantes y Recreacion",
  sub_industry: "Hoteles, Centros de Vacaciones y Cruceros",
  code: "25301020",
  description: "Propietarios y operadores de hoteles, centros de vacaciones y cruceros. Se incluyen las agencias de viajes, operadores turísticos y empresas de servicios relacionados, no incluidas en otra clasificación. Se excluyen los hoteles casino incluidos en la rama Casinos y Juegos de Azar."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Servicios al Consumidor",
  industry: "Hoteles, Restaurantes y Recreacion",
  sub_industry: "Centros Recreativos",
  code: "25301030",
  description: "Propietarios y operadores de centros de recreación. Se incluyen los centros deportivos y gimnasios, estadios, campos de golf y parques de atracciones, no incluidos en la rama Películas y Entretenimiento."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Servicios al Consumidor",
  industry: "Hoteles, Restaurantes y Recreacion",
  sub_industry: "Restaurantes",
  code: "25301040",
  description: "Propietarios y operadores de restaurantes, bares, pubs y locales de comida rápida o para llevar. Se incluyen las empresas de catering."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Servicios al Consumidor",
  industry: "Servicios de Consumo Diversificados",
  sub_industry: "Servicios de Educación",
  code: "25302010",
  description: "Empresas que proporcionan servicios de educación, de forma electrónica o mediante métodos tradicionales de enseñanza. Se Incluyen las universidades privadas, enseñanza a distancia, gestores de seminarios educacionales, material y educación técnica. Quedan excluidas las empresas que proporcionan programas de educación a empleados clasificados en la subindustria de Recursos Humanos y Servicios de Empleo."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Servicios al Consumidor",
  industry: "Servicios de Consumo Diversificados",
  sub_industry: "Servicios de Consumo Especializados",
  code: "25302020",
  description: "Empresas que proporcionan servicios no incluidos en ningún otro apartado. Se incluyen los servicios residenciales, seguridad del hogar, servicios legales, servicios personales, renovación y de diseño interior, subastas de consumidores y servicios de matrimonios y de entierro."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Distribuidores",
  sub_industry: "Distribuidores",
  code: "25501010",
  description: "Distribuidores y mayoristas de productos generales no clasificados en otra sección. Se incluyen los distribuidores de vehículos."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas por Internet y Marketing Directo",
  sub_industry: "Ventas por Internet y Marketing Directo",
  code: "25502020",
  description: "Empresas dedicadas principalmente a servicios de ventas por Internet, por correo o por televisión. También incluye compañías proveedoras de mercados en línea para el comusmo de bienes y servicios."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas Multilínea",
  sub_industry: "Grandes Almacenes",
  code: "25503010",
  description: "Propietarios y operadores de grandes almacenes."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas Multilínea",
  sub_industry: "Establecimientos de Venta de Productos Generales",
  code: "25503020",
  description: "Propietarios y operadores de establecimientos de venta de productos generales variados. Se excluyen los hipermercados y grandes superficies pertenecientes a la rama Hipermercados y Grandes Superficies."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas especializadas",
  sub_industry: "Venta de Ropa",
  code: "25504010",
  description: "Comerciantes especializados principalmente en la venta de ropa y accesorios."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas especializadas",
  sub_industry: "Venta de Ordenadores y Productos Electrónicos",
  code: "25504020",
  description: "Propietarios y operadores de establecimientos de venta de productos electrónicos, ordenadores y artículos relacionados destinados a consumidores particulares."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas especializadas",
  sub_industry: "Venta de Mejoras para el Hogar",
  code: "25504030",
  description: "Propietarios y operadores de establecimientos de venta de utensilios de bricolaje y jardinería. Se incluyen los establecimientos de venta y suministro de materiales de construcción."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas especializadas",
  sub_industry: "Tiendas Especializadas",
  code: "25504040",
  description: "Propietarios y operadores de tiendas especializadas no clasificadas en otra sección. Se incluyen las joyerías, jugueterías, tiendas de artículos para oficina, tiendas de artículos para la salud y ópticas, además de librerías y tiendas de material de recreación."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas especializadas",
  sub_industry: "Venta de Automóviles",
  code: "25504050",
  description: "Propietarios y operadores de tiendas especializadas en productos automotrices. Se incluyen los concesionarios de automóviles, gasolineras y la venta de accesorios, motocicletas y sus piezas, de cristalería del automóvil, y piezas y equipos de automoción."
}, {
  sector: "Consumo Discrecional",
  industry_group: "Venta al por Menor",
  industry: "Ventas especializadas",
  sub_industry: "Venta de Equipamiento para el Hogar",
  code: "25504060",
  description: "Propietarios y operadores de mobiliario y tiendas de equipamiento para el hogar. Se incluye el mobiliario de residencias, apartamentos, almacenes y diseño interior. Se excluyen las tiendas de mejoras de jardines y casas, clasificadas en la rama industrial de Venta de Mejoras para el Hogar."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Venta de Alimentos y Productos de Primera Necesidad",
  industry: "Venta de Alimentos y Productos de Primera Necesidad",
  sub_industry: "Venta de Medicamentos",
  code: "30101010",
  description: "Propietarios y operadores de farmacias y establecimientos dedicados principalmente a la venta de medicamentos."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Venta de Alimentos y Productos de Primera Necesidad",
  industry: "Venta de Alimentos y Productos de Primera Necesidad",
  sub_industry: "Distribución de Alimentos",
  code: "30101020",
  description: "Distribuidores de alimentos a otras empresas (no a consumidores particulares)."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Venta de Alimentos y Productos de Primera Necesidad",
  industry: "Venta de Alimentos y Productos de Primera Necesidad",
  sub_industry: "Venta de Alimentos",
  code: "30101030",
  description: "Propietarios y operadores de establecimientos dedicados principalmente a la venta de alimentos."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Venta de Alimentos y Productos de Primera Necesidad",
  industry: "Venta de Alimentos y Productos de Primera Necesidad",
  sub_industry: "Hipermercados y Grandes Superficies",
  code: "30101040",
  description: "Propietarios y operadores de hipermercados y grandes superficies, dedicados a la venta de alimentos y una amplia gama de productos de primera necesidad. Se excluyen los comerciantes dedicados a alimentos y medicamentos clasificados en las ramas Venta de Alimentos y Venta de Medicamentos, respectivamente."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Alimentos, Bebidas y Tabaco",
  industry: "Bebidas",
  sub_industry: "Producción de Cerveza",
  code: "30201010",
  description: "Empresas dedicadas a la producción de cerveza y licores de malta. Se incluyen las fábricas de cerveza no clasificadas en la rama industrial Restaurantes."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Alimentos, Bebidas y Tabaco",
  industry: "Bebidas",
  sub_industry: "Destiladores y Vinateros",
  code: "30201020",
  description: "Destiladores, vinateros y productores de bebidas alcohólicas no clasificados en la rama industrial Producción de Cerveza."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Alimentos, Bebidas y Tabaco",
  industry: "Bebidas",
  sub_industry: "Producción de Bebidas no Alcohólicas",
  code: "30201030",
  description: "Empresas dedicadas a la producción de bebidas no alcohólicas, incluyendo el agua mineral. Se excluyen los productores de leche, clasificados en la rama industrial Alimentos Envasados."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Alimentos, Bebidas y Tabaco",
  industry: "Alimentos",
  sub_industry: "Productos Agrícolas",
  code: "30202010",
  description: "Empresas dedicadas a la producción agrícola. Se incluyen propietarios de tierras de cultivo y plantaciones, y empresas que producen y procesan alimentos, pero no los envasan ni comercializan. Se excluyen las empresas clasificadas en la rama Productos Madereros y aquellas dedicadas a envasar y comercializar alimentos, clasificadas en la rama Alimentos y Carnes Envasados."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Alimentos, Bebidas y Tabaco",
  industry: "Alimentos",
  sub_industry: "Alimentos y Carnes Envasados",
  code: "30202030",
  description: "Empresas dedicadas a la producción de alimentos envasados como productos lácteos, zumos de frutas, carnes y productos avícolas, pescado y comida para animales."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Alimentos, Bebidas y Tabaco",
  industry: "Tabaco",
  sub_industry: "Tabaco",
  code: "30203010",
  description: "Empresas dedicadas a la producción de cigarrillos y otros productos relacionados."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Productos Domésticos y Personales",
  industry: "Productos Domésticos",
  sub_industry: "Productos Domésticos",
  code: "30301010",
  description: "Empresas dedicadas a la producción de artículos domésticos no duraderos, como detergentes, jabones, pañales y otros productos domésticos de papel, como pañuelos, no clasificados en la rama Productos Papeleros."
}, {
  sector: "Productos de Primera Necesidad",
  industry_group: "Productos Domésticos y Personales",
  industry: "Productos Personales",
  sub_industry: "Productos Personales",
  code: "30302010",
  description: "Empresas dedicadas a la producción de artículos personales y de belleza, como cosméticos y perfumes."
}, {
  sector: "Salud",
  industry_group: "Equipos y Servicios Medicos",
  industry: "Equipos y Suministros Médicos",
  sub_industry: "Equipos Médicos",
  code: "35101010",
  description: "Empresas dedicadas a la fabricación de equipo y dispositivos para la atención de la salud. Se incluyen fabricantes de instrumentos médicos, sistemas de administración de medicamentos, dispositivos cardiovasculares, ortopédicos y equipos de diagnóstico."
}, {
  sector: "Salud",
  industry_group: "Equipos y Servicios Medicos",
  industry: "Equipos y Suministros Médicos",
  sub_industry: "Suministros Medicos",
  code: "35101020",
  description: "Empresas dedicadas a la fabricación de suministros médicos y productos médicos no incluidos en otra clasificación. Se incluyen fabricantes de productos oftalmológicos, suministros para hospitales y dispositivos de seguridad de aguja y jeringa."
}, {
  sector: "Salud",
  industry_group: "Equipos y Servicios Medicos",
  industry: "Proveedores de Servicios Médicos",
  sub_industry: "Distribuidores de Productos Médicos",
  code: "35102010",
  description: "Distribuidores y mayoristas de productos médicos no clasificados en ninguna otra rama."
}, {
  sector: "Salud",
  industry_group: "Equipos y Servicios Medicos",
  industry: "Proveedores de Servicios Médicos",
  sub_industry: "Servicios Médicos",
  code: "35102015",
  description: "Empresas proveedoras de servicios para la atención de pacientes, no clasificadas en ninguna otra rama. Se incluyen centros de diálisis, servicios de pruebas de laboratorio y servicios de administración de farmacias. También se incluyen las empresas que prestan servicios comerciales de apoyo a los proveedores de atención médica, como los servicios administrativos de apoyo, los servicios de agencias de cobranzas, los servicios de personal y los servicios de ventas y mercadeo contratados externamente."
}, {
  sector: "Salud",
  industry_group: "Equipos y Servicios Medicos",
  industry: "Proveedores de Servicios Médicos",
  sub_industry: "Establecimientos Médicos",
  code: "35102020",
  description: "Propietarios y operadores de centros médicos. Se incluyen hospitales, hogares de ancianos, centros de rehabilitación y clínicas veterinarias."
}, {
  sector: "Salud",
  industry_group: "Equipos y Servicios Medicos",
  industry: "Proveedores de Servicios Médicos",
  sub_industry: "Administración de Salud",
  code: "35102030",
  description: "Propietarios y operadores de empresas de seguros médicos y otros servicios de salud."
}, {
  sector: "Salud",
  industry_group: "Equipos y Servicios Medicos",
  industry: "Servicios Tecnológicos para el Área de la Salud",
  sub_industry: "Servicios Tecnológicos para el Área de la Salud",
  code: "35103010",
  description: "Empresas que prestan servicios de tecnología de la información, principalmente a proveedores de atención médica. Se incluyen las empresas proveedoras de software de aplicaciones, sistemas o procesamiento de datos, herramientas disponibles por Internet y servicios de consultoría tecnológica para a médicos, hospitales o empresas que operan fundamentalmente en el área de la salud."
}, {
  sector: "Salud",
  industry_group: "Productos Farmacéuticos, Biotecnología y Ciencias de la Salud",
  industry: "Biotecnología",
  sub_industry: "Biotecnología",
  code: "35201010",
  description: "Empresas dedicadas principalmente a la investigación, desarrollo y producción y/o comercialización de productos de ingeniería genética o análisis genético. Incluye compañías especializadas en terapia con base en proteínas para el tratamiento de enfermedades humanas. Se excluyen las empresas fabricantes de productos biotecnológicos sin uso en la salud."
}, {
  sector: "Salud",
  industry_group: "Productos Farmacéuticos, Biotecnología y Ciencias de la Salud",
  industry: "Productos Farmacéuticos",
  sub_industry: "Productos Farmacéuticos",
  code: "35202010",
  description: "Empresas dedicadas a la investigación, desarrollo o producción de productos farmacéuticos. Se incluyen los fármacos veterinarios."
}, {
  sector: "Salud",
  industry_group: "Productos Farmacéuticos, Biotecnología y Ciencias de la Salud",
  industry: "Herramientas y Servicios para las Ciencias de la Salud",
  sub_industry: "Herramientas y Servicios para las Ciencias de la Salud",
  code: "35203010",
  description: "Empresas que facilitan el continuo descubrimiento, desarrollo y producción de fármacos, mediante el suministro de herramientas de análisis, instrumentos, productos consumibles y suministros, servicios de ensayos clínicos y servicios contractuales de investigación. Se incluyen las empresas que atienden principalmente a las industrias farmacéutica y de biotecnología."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Bancarias",
  industry: "Bancos Comerciales",
  sub_industry: "Bancos Diversificados",
  code: "40101010",
  description: "Bancos de gran tamaño, con presencia nacional y servicios diversos, cuyos ingresos provienen principalmente de operaciones bancarias tradicionales y que tienen actividades de negocios significativas en banca de servicios y préstamos a empresas medianas y pequeñas, además de proveer una gama amplia de servicios financieros. Se excluyen los bancos clasificados en la rama de Bancos Regionales y la de Ahorro y Financiamento de Hipotecas. Se excluyen también los bancos clasificados en la rama industrial de Inversión y Correduría."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Bancarias",
  industry: "Bancos Comerciales",
  sub_industry: "Bancos Regionales",
  code: "40101015",
  description: "Bancos comerciales cuyo negocio proviene principalmente de operaciones bancarias tradicionales y tienen actividades de negocios significativas en banca de servicios y prestamos a  pequeñas y medianas empresas. Los bancos regionales operan usualmente en una región geografica determinada. Se excluyen las empresas clasificadas en la ramas de Bancos Diversificados y de Ahorro y Financiamiento de Hipotecas. Se excluyen también los bancos de inversión, clasificados en la rama de Bancos de Inversión y Correduría."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Bancarias",
  industry: "Ahorro y Financiamiento de Hipotecas",
  sub_industry: "Ahorro y Financiamiento de Hipotecas",
  code: "40102010",
  description: "Instituciones financieras que proporcionan hipotecas y servicios relacionados. Se incluyen las instituciones financieras cuyos activos están relacionados principalmente con hipotecas, ahorros y préstamos, instituciones de préstamos hipotecarios, cooperativas de crédito y empresas de seguros para bancos hipotecarios."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Servicios Financieros Diversificados",
  sub_industry: "Otros Servicios Financieros Diversificados",
  code: "40201020",
  description: "Provedores de diversos servicios financieros y/o con interés en una variedad de servicios financieros, incluyendo servicios bancarios, seguros y mercados de capitales, mas sin ninguno de estos como negocio predominante. Se excluyen las empresas clasificadas en las ramas de Bancos Regionales y Bancos Diversificados."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Servicios Financieros Diversificados",
  sub_industry: "Sociedades de Cartera Multisector",
  code: "40201030",
  description: "Empresas con participaciones accionarias altamente diversificadas en tres o más sectores, de los cuales ninguno corresponde individualmente a la mayor parte de los beneficios y/o ventas.  Las participaciones que tienen son predominantemente de carácter no estratégico. Se incluyen empresas financieras diversificadas en las que las participaciones son de carácter estratégico. Se excluyen otras empresas diversificadas, clasificadas en la rama Conglomerados Industriales."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Servicios Financieros Diversificados",
  sub_industry: "Servicios Financieros Especializados",
  code: "40201040",
  description: "Empresas que proporcionan servicios financieros especializados, no incluidas en ninguna otra categoría y que obtienen la mayoría de sus ingresos de una línea de negocios especializada. Se incluyen, entre otras, empresas de financiamiento privado, bancos centrales, servicios de factoraje y arrendamiento y empresas especializadas. Se excluyen las empresas clasificadas en la rama de Información y Mercados Financieros."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Servicios Financieros Personales",
  sub_industry: "Servicios Financieros Personales",
  code: "40202010",
  description: "Empresas dedicadas a los servicios financieros personales. Se incluyen las empresas de crédito personal, tarjetas de crédito, financiación de arrendamiento, servicios financieros para viajes y casas de empeño. Se excluyen los prestamistas hipotecarios, clasificados en la rama Ahorro y Financiamiento de Hipotecas."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Mercados de Capitales",
  sub_industry: "Gestión de Activos y Bancos de Custodia",
  code: "40203010",
  description: "Instituciones financieras dedicadas principalmente a la gestión de inversiones y/o servicios de custodia de valores. Se incluyen las empresas que operan fondos de inversión, fondos cerrados y fideicomisos de inversión independientes. Se excluyen los bancos y otras instituciones financieras dedicadas principalmente a préstamos comerciales, banca de inversión, correduría y otras actividades financieras especializadas."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Mercados de Capitales",
  sub_industry: "Bancos de Inversión y Corredurías",
  code: "40203020",
  description: "Instituciones financieras dedicadas principalmente a los servicios bancarios de inversión y de correduría, incluyendo la suscripción de capital y deuda, las fusiones y las adquisiciones, el préstamo de valores y los servicios de asesoramiento. Se excluyen los bancos y otras instituciones financieras dedicadas principalmente a los préstamos comerciales, la gestión de activos y otras actividades financieras especializadas."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Mercados de Capitales",
  sub_industry: "Mercados de Capitales Diversificados",
  code: "40203030",
  description: "Instituciones financieras dedicadas principalmente a actividades diversas en mercados de capitales, con una presencia significativa en al menos dos de las siguientes áreas: préstamos a grandes empresas o corporaciones, banca de inversión, correduría y gestión de activos. Se excluyen las empresas menos diversificadas, clasificadas en la rama Gestión de Activos y Servicios de Custodia de Valores o la de Bancos de Inversión y Corredurías. También se excluyen las empresas clasificadas en las ramas industriales de Entidades Bancarias o Seguros, o en la rama Servicios Financieros Personales."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Mercados de Capitales",
  sub_industry: "Información y Mercados Financieros",
  code: "40203040",
  description: "Empresas dedicadas al intercambio financiero de títulos, productos, instrumentos financieros derivados y otros, así como proveedores de herramientas y productos de apoyo en decisiones financieras, incluyendo las agencias de calificación."
}, {
  sector: "Finanzas",
  industry_group: "Entidades Financieras Diversas",
  industry: "Fideicomiso Hipotecario de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT Hipotecarias",
  code: "40204010",
  description: "Empresas o fideicomisos que dan servicio, originan, compran o aseguran préstamos hipotecarios residenciales o comerciales. Se incluyen los fideicomisos que invierten en valores con garantía hipotecaria y otros activos relacionados con las hipotecas."
}, {
  sector: "Finanzas",
  industry_group: "Seguros",
  industry: "Seguros",
  sub_industry: "Agentes de Seguros",
  code: "40301010",
  description: "Agencia de corredores de seguros o reaseguros."
}, {
  sector: "Finanzas",
  industry_group: "Seguros",
  industry: "Seguros",
  sub_industry: "Seguros de Vida y Salud",
  code: "40301020",
  description: "Empresas dedicadas a servicios de seguros de vida, invalidez, indemnización o suplementarios. Se excluyen las empresas de administración de salud, clasificadas en la rama Administración de Salud."
}, {
  sector: "Finanzas",
  industry_group: "Seguros",
  industry: "Seguros",
  sub_industry: "Seguros Diversos",
  code: "40301030",
  description: "Empresas aseguradoras que ofrecen servicios diversos de seguros de vida, enfermedad, propiedad y contra accidentes."
}, {
  sector: "Finanzas",
  industry_group: "Seguros",
  industry: "Seguros",
  sub_industry: "Seguro de Propiedad y contra Accidentes",
  code: "40301040",
  description: "Empresas aseguradoras que ofrecen principalmente seguros de propiedad y contra accidentes."
}, {
  sector: "Finanzas",
  industry_group: "Seguros",
  industry: "Seguros",
  sub_industry: "Reaseguros",
  code: "40301050",
  description: "Empresas dedicadas principalmente al reaseguro."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Software y Servicios",
  industry: "Servicios de Tecnología de la Información",
  sub_industry: "Consultoría de Tecnología de la Información y Otros Servicios",
  code: "45102010",
  description: "Empresas que proporcionan servicios relacionados con la tecnología de la información y la integración de sistemas, no clasificados en las ramas Procesamiento de Datos y Servicios Subcontratados, ni en la de Software y Servicios de Internet. Se incluyen los servicios de consultoría de tecnología de la información y sevicios de administración de información."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Software y Servicios",
  industry: "Servicios de Tecnología de la Información",
  sub_industry: "Procesamiento de Datos y Servicios Subcontratados",
  code: "45102020",
  description: "Empresas que proporcionan servicios de procesamiento electrónico de datos comerciales y/o servicios de subcontratación de procesos empresariales. Se incluyen empresas que proporcionan servicios de automatización de Back Office."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Software y Servicios",
  industry: "Servicios de Tecnología de la Información",
  sub_industry: "Servicios de Internet e Infraestructura",
  code: "45102030",
  description: "Empresas que brindan servicios e infraestructura para la industria de Internet, incluidos los centros de datos y la infraestructura de redes y almacenamiento en la nube. También incluye compañías que brindan servicios de alojamiento web. No incluye las empresas clasificadas en la Industria de Software."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Software y Servicios",
  industry: "Software",
  sub_industry: "Aplicaciones de Software",
  code: "45103010",
  description: "Empresas dedicadas al desarrollo y la producción de programas informáticos con aplicaciones específicas para el mercado de empresas o particulares. Se incluyen los programas técnicos y para empresas, así como software basado en la nube. Se excluyen las empresas clasificadas en la  rama industrial de Entretenimiento Doméstico Interactivo. También se excluyen las empresas que crean programas de gestión de sistemas o bases de datos, clasificadas en la rama Software de Sistemas."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Software y Servicios",
  industry: "Software",
  sub_industry: "Software de Sistemas",
  code: "45103020",
  description: "Empresas dedicadas al desarrollo y producción de programas para la administración de sistemas o bases de datos."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Equipos Tecnológico y Maquinarias",
  industry: "Equipos de Comunicaciones",
  sub_industry: "Equipos de Comunicacioness",
  code: "45201020",
  description: "Fabricantes de equipo y productos de comunicaciones, incluyendo las redes de área local (LAN), redes de área amplia (WAN), enrutadores, teléfonos, conmutadores y centrales telefónicas. Se excluyen los fabricantes de teléfonos celulares, clasificados en la rama de Equipos Tecnológicos, Almacenamiento y Periféricos."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Equipos Tecnológico y Maquinarias",
  industry: "Equipos Tecnológicos, Almacenamiento Electrónico y Periféricos",
  sub_industry: "Equipos Tecnológicos, Almacenamiento Electrónico y Periféricos",
  code: "45202030",
  description: "Fabricantes de teléfonos celulares, computadoras personales, servidores, componentes electrónicos de computadoras y periféricos. Se incluyen los fabricantes de componentes de almacenamiento electrónico de información, placas base, tarjetas de sonido y video, monitores, teclados, impresoras y otros periféricos. Se excluyen los semiconductores, incluidos en la rama de Semiconductores."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Equipos Tecnológico y Maquinarias",
  industry: "Equipos, Instrumentos y Componentes Electrónicos",
  sub_industry: "Equipos e Instrumentos Electrónicos",
  code: "45203010",
  description: "Fabricantes de equipos e instrumentos electrónicos, incluyendo los instrumentos analíticos, de prueba y medición electrónica, productos de escáner y lectores de códigos de barras, rayos láser, pantallas, terminales de punto de venta y equipo para sistemas de seguridad."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Equipos Tecnológico y Maquinarias",
  industry: "Equipos, Instrumentos y Componentes Electrónicos",
  sub_industry: "Componentes Electrónicos",
  code: "45203015",
  description: "Fabricantes de componentes electrónicos. Se incluyen los componentes electrónicos, dispositivos de conexión, tubos electrónicos al vacío, condensadores y resistencias eléctricas, bobinas electrónicas, placas de circuitos impresos, transformadores y otros inductores, componentes y tecnología de procesamiento de señales."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Equipos Tecnológico y Maquinarias",
  industry: "Equipos, Instrumentos y Componentes Electrónicos",
  sub_industry: "Servicios de Fabricación Electrónica",
  code: "45203020",
  description: "Empresas dedicadas a la producción de equipos electrónicos, principalmente para los mercados de fabricantes de equipos originales (OEM)."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Equipos Tecnológico y Maquinarias",
  industry: "Equipos, Instrumentos y Componentes Electrónicos",
  sub_industry: "Distribuidores de Productos Tecnológicos",
  code: "45203030",
  description: "Empresas dedicadas a la distribución de equipo tecnológico y maquinarias. Se incluyen las empresas distribuidoras de equipo de comunicaciones, ordenadores y periféricos, semiconductores, así como equipos y componentes electrónicos."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Semiconductores y Equipos Relacionados",
  industry: "Semiconductores y Equipos Relacionados",
  sub_industry: "Equipo Relacionados a Semiconductores",
  code: "45301010",
  description: "Empresas dedicadas a la fabricación de equipos relacionados a semiconductores. Se incluyen, entre otros, los fabricantes de materias primas y equipos utilizados en la industria de energía solar."
}, {
  sector: "Tecnologías de la Información",
  industry_group: "Semiconductores y Equipos Relacionados",
  industry: "Semiconductores y Equipos Relacionados",
  sub_industry: "Semiconductores",
  code: "45301020",
  description: "Empresas dedicadas a la fabricación de semiconductores y productos relacionados. Se incluyen los fabricantes de módulos y paneles solares."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Servicios de Telecomunicaciones",
  industry: "Servicios de Telecomunicaciones Diversos",
  sub_industry: "Portadores Alternativos",
  code: "50101010",
  description: "Empresas que proporcionan servicios de comunicación y transmisión de datos de alta densidad, principalmente mediante redes de cable de banda ancha o fibra óptica."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Servicios de Telecomunicaciones",
  industry: "Servicios de Telecomunicaciones Diversos",
  sub_industry: "Servicios Integrados de Telecomunicación",
  code: "50101020",
  description: "Operadores de redes de telecomunicaciones, principalmente de línea fija y empresas que ofrecen servicios de telecomunicaciones de línea fija o inalámbricas, no incluidas en otra clasificación. También incluye a los proveedores de servicios de internet que ofrecen acceso a usuarios finales."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Servicios de Telecomunicaciones",
  industry: "Servicios de Telecomunicación Inalámbrica",
  sub_industry: "Servicios de Telecomunicación Inalámbrica",
  code: "50102010",
  description: "Empresas que ofrecen principalmente servicios de telecomunicación inalámbrica o celulares."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Medios de Comunicación y Entretenimiento",
  industry: "Medios de Comunicación",
  sub_industry: "Publicidad",
  code: "50201010",
  description: "Empresas dedicadas a servicios de publicidad, marketing o relaciones públicas."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Medios de Comunicación y Entretenimiento",
  industry: "Medios de Comunicación",
  sub_industry: "Difusión por Radio y Televisión",
  code: "50201020",
  description: "Propietarios y operadores de sistemas de difusión por radio y televisión, incluida la programación. Incluye emisoras de radio y televisión, cadenas radiofónicas y estaciones de radio."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Medios de Comunicación y Entretenimiento",
  industry: "Medios de Comunicación",
  sub_industry: "Televisión por Cable y Vía Satélite",
  code: "50201030",
  description: "Proveedores de servicios de televisión por cable o vía satélite. Incluye cadenas de cable y distribución de programación."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Medios de Comunicación y Entretenimiento",
  industry: "Medios de Comunicación",
  sub_industry: "Publicación",
  code: "50201040",
  description: "Empresas dedicadas a la publicación de periódicos, revistas y libros y proveedores de información en formato impreso o electrónico."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Medios de Comunicación y Entretenimiento",
  industry: "Entretenimiento",
  sub_industry: "Películas y Entretenimiento",
  code: "50202010",
  description: "Empresas dedicadas a la producción y venta de productos y servicios de entretenimiento. Se incluyen las empresas dedicadas a la producción, distribución y proyección de películas y programas televisivos, productores y distribuidores de música, teatros y equipos (clubes) deportivos. También incluye compañías que ofrecen y/o producen contenidos de entretenimiento para proyectarse en línea"
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Medios de Comunicación y Entretenimiento",
  industry: "Entretenimiento",
  sub_industry: "Entretenimiento Doméstico Interactivo",
  code: "50202020",
  description: "Empresas que producen juegos interactivos, incluyendo aplicaciones moviles para juegos. También incluye software educativo usado principalmente en el hogar. Excluye a las compañías dedicadas a los juegos de azar clasificadas en la rama de Casinos y Juegos de Azar."
}, {
  sector: "Servicios de Comunicación",
  industry_group: "Medios de Comunicación y Entretenimiento",
  industry: "Medios y Servicios Interactivos",
  sub_industry: "Medios y Servicios Interactivos",
  code: "50203010",
  description: "Compañías dedicadas a la creación o distribución de contenido e información a través de plataformas propias, cuyos ingresos provengan principalmente de anuncios pagados por un clic. Incluirá motores de búsqueda, redes sociales y plataformas de redes, clasificados en línea y compañías de revisión en línea. Excluye a las compañías operadoras de mercados en línea, clasificadas dentro de Ventas por Internet y Marketing Directo"
}, {
  sector: "Servicios de Utilidad Pública",
  industry_group: "Servicios de Utilidad Pública",
  industry: "Suministro Eléctrico",
  sub_industry: "Suministro Eléctrico",
  code: "55101010",
  description: "Empresas dedicadas a la generación o distribución de electricidad. Se incluyen tanto las centrales nucleares como las no nucleares."
}, {
  sector: "Servicios de Utilidad Pública",
  industry_group: "Servicios de Utilidad Pública",
  industry: "Suministro de Gas",
  sub_industry: "Suministro de Gas",
  code: "55102010",
  description: "Empresas cuyo principal objetivo es la distribución y transmisión de gas natural o manufacturado. Se excluyen las empresas dedicadas principalmente a la exploración o producción de gas, incluidas en la clasificación de Producción y Exploración de Petróleo y Gas. También se excluyen las empresas procesadoras de gas natural diversificadas, incluidas en la rama Refinado, Comercialización, Almacenado y  Transporte de Petróleo y Gas."
}, {
  sector: "Servicios de Utilidad Pública",
  industry_group: "Servicios de Utilidad Pública",
  industry: "Multiservicios",
  sub_industry: "Multiservicios",
  code: "55103010",
  description: "Empresas de servicios con actividades diversificadas, además de los servicios de suministro de electricidad, gas o agua."
}, {
  sector: "Servicios de Utilidad Pública",
  industry_group: "Servicios de Utilidad Pública",
  industry: "Suministro de Agua",
  sub_industry: "Suministro de Agua",
  code: "55104010",
  description: "Empresas que compran y redistribuyen agua al consumidor final. Se incluyen los sistemas de tratamiento de agua a gran escala."
}, {
  sector: "Servicios de Utilidad Pública",
  industry_group: "Servicios de Utilidad Pública",
  industry: "Productores de Energía Independientes y de Energía Eléctrica Renovable",
  sub_industry: "Productores de Energía Independientes y Operadores de Energía",
  code: "55105010",
  description: "Empresas que operan como productoras independientes de energía (IPP), mercadeo de gas y energía, especialistas de comercio y comerciantes de energía integrada. Se incluyen los productores de energía con fuentes alternativas, como la solar, hidroeléctrica y eólica. Se excluyen las empresas de transmisión y distribución eléctrica, clasificadas en la rama de Suministro Eléctrico."
}, {
  sector: "Servicios de Utilidad Pública",
  industry_group: "Servicios de Utilidad Pública",
  industry: "Productores de Energía Independientes y de Energía Eléctrica Renovable",
  sub_industry: "Energías Alternativas",
  code: "55105020",
  description: "Empresas que operan en la generación y distribución de electricidad de fuentes alternativas, incluidas aquellas que producen electricidad usando biomasa, energía geotérmica, energía solar, energía hidroeléctrica y energía eólica. Se excluyen las empresas que fabrican equipos usados para la generación de energía con fuentes alternativas, como los fabricantes de sistemas de paneles solares, empresas que instalan celdas fotovoltáicas, y empresas dedicadas a proveer tecnología, partes y sevicios principalmente en este mercado."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT Diversificadas",
  code: "60101010",
  description: "Empresas o fideicomisos con operaciones considerablemente diversificadas en dos o más tipos de inmuebles."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT Industriales",
  code: "60101020",
  description: "Empresas o fideicomisos dedicados a la adquisición, desarrollo, propiedad, alquiler, administración y operación de bienes inmuebles industriales. Se incluyen las empresas que operan almacenes industriales e inmuebles de distribución."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT de Hoteles y Propiedades de Recreación",
  code: "60101030",
  description: "Empresas o fideicomisos dedicados a la adquisición, desarrollo, posesión, alquiler, administración y operación de hoteles y propiedades de recreación."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT de Oficinas",
  code: "60101040",
  description: "Empresas o fideicomisos dedicados a la adquisición, desarrollo, posesión, alquiler, administración y operación de inmuebles para oficinas."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT de Propiedades Dedicadas al Cuidado de Salud",
  code: "60101050",
  description: "Empresas o fideicomisos dedicados a la adquisición, desarrollo, posesión, alquiler, administración y operación de inmuebles para servicios médicos, incluyendo hospitales, asilos y centros de vida asistida."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT Residenciales",
  code: "60101060",
  description: "Empresas o fideicomisos dedicados a la adquisición, desarrollo, posesión, alquiler, administración y operación de inmuebles residenciales. Se incluyen las viviendas multifamiliares, departamentos, casas prefabricadas e inmuebles para alojamiento de estudiantes."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT Comerciales",
  code: "60101070",
  description: "Empresas o fideicomisos dedicados a la adquisición, desarrollo, posesión, alquiler, administración y operación de centros comerciales, tiendas al por menor y centros comerciales en barrios y comunidades."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Fideicomisos Hipotecarios de Inversión en Bienes Inmobiliarios (REIT)",
  sub_industry: "REIT Especializados",
  code: "60101080",
  description: "Empresas o fideicomisos dedicados a la adquisición, desarrollo, posesión, alquiler, administración y operación de propiedades no clasificados previamente. Incluye fideicomisos que operan e invierten en inmuebles de almacenamiento. Tambien incluye REIT que no generan la mayoria de sus ingresos del alquiler o arrendamiento de inmuebles."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Desarrollo y Administración de Bienes Inmobiliarios",
  sub_industry: "Actividades Diversificadas Relacionadas con Bienes Inmobiliarios",
  code: "60102010",
  description: "Empresas dedicadas a un espectro diverso de actividades relacionadas con los bienes inmobiliarios que incluyen el desarrollo y venta de bienes inmuebles, la administración de bienes inmuebles o servicios para bienes inmuebles, pero sin una línea de negocios dominante."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Desarrollo y Administración de Bienes Inmobiliarios",
  sub_industry: "Empresas Operadoras de Bienes Inmobiliarios",
  code: "60102020",
  description: "Empresas dedicadas a operar propiedades inmobiliarias para efectos de alquiler y administración."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Desarrollo y Administración de Bienes Inmobiliarios",
  sub_industry: "Desarrollo Inmobiliario",
  code: "60102030",
  description: "Empresas que desarrollan bienes inmuebles y venden las propiedades después de urbanizarlas. Se excluyen empresas clasificadas en la rama de Construcción de Viviendas."
}, {
  sector: "Bienes Inmobiliarios",
  industry_group: "Bienes Inmobiliarios",
  industry: "Desarrollo y Administración de Bienes Inmobiliarios",
  sub_industry: "Servicios Inmobiliarios",
  code: "60102040",
  description: "Proveedores de servicios inmobiliarios, como agentes de bienes raíces, corredores y tasadores de bienes inmuebles."
}];

export function getIndustryClassifications(): IIndustryClassification[] {
  return industryClassifications;
} 