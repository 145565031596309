interface MaxWMDContainerProps {
  children: React.ReactNode;
  className?: string;
}

export default function MaxWMDContainer({ children, className = '' }: MaxWMDContainerProps): JSX.Element {
  return (
    <div className={`sm:mx-auto sm:w-full sm:max-w-md ${className}`}>
      {children}
    </div>
  )
}