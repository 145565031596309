class TokenService {
  getLocalAccessToken() {
    return localStorage.getItem('token');
  }

  updateLocalAccesToken(token: string) {
    localStorage.setItem('token', token);
  }
}

const tokenService = new TokenService();

export { tokenService };