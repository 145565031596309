import React, { Dispatch } from 'react';
import { User } from '../interfaces/user';


interface AppContextProps {
  user?: User;
  setUser: (user: User | undefined) => void;
}

export const AppContext = React.createContext<AppContextProps>(Object.assign({}));
